import React, { useEffect, useMemo, useRef, useState } from 'react';
import { hexToRgba } from '@/lib/hexToRgb';
import { Group, Line } from 'react-konva';
import { PolygonData } from '@/api/layer';
import { useGlobalStore } from '@/stores/globalStore';
import { animated, useSpring } from '@react-spring/konva';
import { easePoly } from 'd3-ease';
import { useMapStore } from '@/stores/mapStore';
import { useProjectStore } from '@/stores/projectStore';

interface PolygonElementProps {
  id: number;
  width: number;
  height: number;
  polygon: PolygonData;
}

export const getMapCoord = (value: number, percent: number) =>
  value * (percent / 100);

const Polygon: React.FC<PolygonElementProps> = ({
  id,
  width,
  height,
  polygon,
}) => {
  const polygonRef = useRef<any>(null);

  const [layer, setLayer] = useState(false);
  const [fill, setFill] = useState(
    hexToRgba(polygon.fill, polygon.alpha / 100),
  );
  const [stroke, setStroke] = useState(
    hexToRgba(polygon.stroke, polygon.alpha / 100),
  );
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer);
  const setLayerModal = useGlobalStore((state) => state.setLayerModal);
  const nodes = useProjectStore((state) => state.nodes);
  const polygonId = useMapStore((state) => state.polygon);
  const setPolygon = useMapStore((state) => state.setPolygon);
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const hoverColor = hexToRgba(polygon.fill, polygon.hover / 100);
  const alphaColor = hexToRgba(polygon.fill, polygon.alpha / 100);
  const setTooltip = useMapStore((state) => state.setTooltip);
  const node = nodes.find((item) => item.id == id);

  const data = polygon.polygon.reduce(
    (acc, val) => [
      ...acc,
      getMapCoord(width, val[0]),
      getMapCoord(height, val[1]),
    ],
    [],
  );

  const onMouseEnterHandler = (e) => {
    const container = e.target.getStage()?.container();

    if (container) {
      container.style.cursor = 'pointer';
    }

    setFill(hexToRgba(polygon.fill, polygon.hover / 100));
    setStroke(hexToRgba(polygon.stroke, polygon.hover / 100));
  };

  const onMouseLeaveHandler = (e) => {
    const container = e.target.getStage()?.container();

    if (container) {
      container.style.cursor = 'default';
    }

    setFill(hexToRgba(polygon.fill, polygon.alpha / 100));
    setStroke(hexToRgba(polygon.stroke, polygon.alpha / 100));
  };

  const handleClick = (e) => {
    e.evt.preventDefault();

    const node = nodes.find((item) => item.id == id);
    if (!node || !node.ownView) {
      setLayerModal(id);
    }

    setActiveLayer(id);
    setTooltip(null);
  };

  const currentPolygon = useMemo(() => polygonId == id, [polygonId, id]);

  const onGroupMouseEnterHandler = React.useCallback(
    (e) => {
      setTooltip(node?.name || null);
    },
    [setTooltip, node],
  );

  const onGroupMouseLeaveHandler = React.useCallback(
    (e) => {
      setTooltip(null);
    },
    [setTooltip],
  );

  useEffect(() => {
    const instance = polygonRef.current;
    instance.on('mouseenter', onGroupMouseEnterHandler);
    instance.on('mouseleave', onGroupMouseLeaveHandler);

    return () => {
      instance.off('mouseenter');
      instance.off('mouseleave');
    };
  });

  const animation = [
    {
      opacity: 0.2,
      fill: hoverColor,
      stroke: 'rgba(255, 120, 126, 1)',
      strokeWidth: 3,
    },
    {
      opacity: 1,
      fill: hoverColor,
      stroke: 'rgba(255, 120, 126, 1)',
      strokeWidth: 3,
    },
    {
      opacity: 0.2,
      fill: hoverColor,
      stroke: 'rgba(255, 120, 126, 1)',
      strokeWidth: 3,
    },
    {
      opacity: 1,
      fill: hoverColor,
      stroke: 'rgba(255, 120, 126, 1)',
      strokeWidth: 3,
    },
  ];

  const [springs, api] = useSpring(() => ({
    from: { opacity: 1, fill: alphaColor, stroke, strokeWidth: 1 },
    to: timeline,
    config: {
      easing: easePoly.exponent(2),
      duration: 250,
    },
    // onRest: () => setPolygon(null),
    // onStart: () => setFill(hexToRgba(polygon.fill, 0))
  }));

  const timeline = [...Array(15)].reduce((acc) => acc.concat(animation), []);
  useEffect(() => {
    const current = polygonId == id;
    if (current) {
      api.start({
        from: { opacity: 1, fill: alphaColor },
        to: timeline,
        onRest: () => {
          api.start({ opacity: 1, fill: alphaColor, stroke, strokeWidth: 1 });
          setPolygon(null);
        },
      });
    } else {
      api.start({
        from: { opacity: 1, fill: alphaColor, stroke, strokeWidth: 1 },
        to: { opacity: 1, fill: alphaColor, stroke, strokeWidth: 1 },
      });
      api.stop();
    }
  }, [polygonId]);

  //
  // useEffect(() => {
  //   const current = activeLayer == id
  //   if (!current) {
  //     api.start({
  //       from: { opacity: 1, fill: alphaColor, stroke, strokeWidth: 1 },
  //       to: { opacity: 1, fill: alphaColor, stroke, strokeWidth: 1 }
  //     })
  //     api.stop()
  //   }
  // }, [activeLayer])

  return (
    <Group ref={polygonRef}>
      {/*// @ts-ignore*/}
      <animated.Line
        points={data}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        closed
        {...springs}
      />
      <Line
        stroke={stroke}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        fill={fill}
        points={data}
        closed
        onClick={handleClick}
        onTap={handleClick}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        id={'layer' + id}
      />
    </Group>
  );
};

export default Polygon;
