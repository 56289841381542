import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { BookingService } from '../services/booking.service';

export const useSeat = (id?: number | string | null) => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();

  const pointId = Number(id);

  const { data, isLoading, refetch } = useQuery(
    ['booking_point', workspaceId, projectId, id],
    () => BookingService.getPoint({ workspaceId, projectId, pointId }),
    {
      enabled: !!workspaceId && !!projectId && !!pointId,
      select: ({ data }) => ({ node: data.node_info }),
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить данные о расположении',
          },
          { variant: 'error' },
        );
      },
    },
  );

  return { data, isLoading, refetch };
};
