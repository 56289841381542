import Close from '@/components/Close';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { FormControls } from '@/components/ui/form/FormControls';
import { Input } from '@/ui/components/Field/Input';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import Konva from 'konva';
import { nanoid } from 'nanoid';
import { ChangeEvent, useState } from 'react';
import styled, { css } from 'styled-components';

const LayerPrintModal = ({
  close: onClose,
  width = 0,
  height = 0,
  stage,
  rescale,
}) => {
  const [eWidth, setWitdth] = useState(width);
  const [eHeight, setHeight] = useState(height);
  const [type, setType] = useState<'jpg' | 'pdf'>('jpg');
  const [print, setPrint] = useState(false);

  const ratio = width / height;

  const handleWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/[^\d]/g, '')) || 0;
    setWitdth(value);
    setHeight(Math.round(value / ratio));
  };
  const handleHeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/[^\d]/g, '')) || 0;
    setHeight(value);
    setWitdth(Math.round(value * ratio));
  };

  const handlePrintLayerWithParams = (width: number, height: number) => {
    setPrint(true);
    setTimeout(() => {
      rescale();
      const cover = (stage as Konva.Stage).findOne('#cover-layer');
      const ratio = width / (cover ? cover.width() : stage.width());
      const data = (stage as Konva.Stage).toDataURL({
        pixelRatio: ratio,
        quality: 1,
        width: cover ? cover.width() : stage.width(),
        height: cover ? cover.height() : stage.height(),
        x: cover ? cover.x() : 0,
        y: cover ? cover.y() : 0,
      });

      const filename = nanoid();
      if (type === 'pdf') {
        var pdf = new jsPDF('l', 'px', [stage.width(), stage.height()]);

        pdf.addImage({
          imageData: stage.toDataURL({ pixelRatio: 2 }),
          format: 'JPEG',
          x: 0,
          y: 0,
          width: stage.width(),
          height: stage.height(),
          alias: undefined,
          compression: 'FAST', //'NONE', 'FAST', 'MEDIUM' and 'SLOW'
          rotation: 0,
        });

        pdf.save(`${filename}.pdf`);
      } else {
        saveAs(data, `${filename}.jpg`);
      }

      setPrint(false);
    }, 1500);
  };

  return (
    <Wrapper>
      <Header>
        <Title>Экспорт уровня</Title>
        <Close
          color="#000"
          onClick={onClose}
        />
      </Header>

      <div>
        <Description>
          Разрешение подложки уровня: {width}x{height}
        </Description>
        <div>
          <Text>
            Введите желаемые параметры изображения
            <br />
            <TextDescription>
              При изменении одного параметра второй меняется с соответствующими
              пропорциями
            </TextDescription>
            <TextDescription style={{ marginTop: 16, marginBottom: 16 }}>
              <p>
                Размеры изображений для стандартных форматов при печати с
                разрешением 300 dpi:
              </p>
              <br />
              <p>А0 - 14043 х 9930 пикселей</p>
              <p>А1 - 9933 х 7016 пикселей</p>
              <p>А2 - 7016 х 4961 пикселей</p>
              <p>А3 - 4961 х 3508 пикселей</p>
              <p>А4 - 3508 х 2480 пикселей</p>
            </TextDescription>
          </Text>
          <InputsContainer>
            <div>
              <InputLabel>Ширина: </InputLabel>
              <Input
                value={eWidth}
                onChange={handleWidthChange}
              />
            </div>
            <div>
              <InputLabel>Высота: </InputLabel>
              <Input
                value={eHeight}
                onChange={handleHeightChange}
              />
            </div>
          </InputsContainer>
        </div>
      </div>

      <div style={{ marginTop: 16 }}>
        <TextDescription>Выберите формат экспорта</TextDescription>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ padding: '12px 0' }}>
            <input
              type="radio"
              id="jpg"
              name="variant"
              value="jpg"
              checked={type === 'jpg'}
              onChange={setType.bind(null, 'jpg')}
            />
            <RadioLabel htmlFor="jpg">JPG</RadioLabel>
          </div>
          <div style={{ padding: '12px 0' }}>
            <input
              type="radio"
              id="pdf"
              name="variant"
              value="pdf"
              checked={type === 'pdf'}
              onChange={setType.bind(null, 'pdf')}
            />
            <RadioLabel htmlFor="pdf">PDF</RadioLabel>
          </div>
        </div>
      </div>
      <FormControls>
        <PrimaryButton
          $fullWidth
          type="button"
          onClick={() => handlePrintLayerWithParams(eWidth, eHeight)}
          disabled={print}
        >
          {print
            ? 'Экспортируем...'
            : `Экспорт в ${type == 'pdf' ? 'PDF' : 'JPG'}`}
        </PrimaryButton>
        <DefaultButton
          $fullWidth
          type="button"
          onClick={onClose}
        >
          Отмена
        </DefaultButton>
      </FormControls>
    </Wrapper>
  );
};

export default LayerPrintModal;

const RadioLabel = styled.label`
  padding-left: 8px;
`;

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
`;

const InputLabel = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
`;

const InputsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const TextDescription = styled.div`
  font-size: 12px;
  color: #a3a3a3;
`;
const Description = styled.div`
  margin-bottom: 32px;
  font-size: 12px;
  color: #a3a3a3;
`;

const Wrapper = styled.div`
  background: #fff;
  max-width: 480px;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
`;

const ButtonBaseStyles = css`
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 1.2rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
`;

export const BookButton = styled.button`
  ${ButtonBaseStyles};

  background: #044d8c;
  color: #fff;
  margin-right: 20px;
`;

export const CancelButton = styled.button`
  ${ButtonBaseStyles};

  background: #bfbfbf;
  color: #fff;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  color: #2c2c2c;
`;
