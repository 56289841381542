import { FormLabel } from '@/ui/components/Form/FormLabel';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import WeeklyInterval from './WeeklyInterval';
import { useFormikContext } from 'formik';
import {
  addDays,
  eachWeekOfInterval,
  endOfWeek,
  getWeek,
  isAfter,
  setDay,
  setWeek,
  startOfWeek,
} from 'date-fns';
import useResponsive from '@/hooks/useResponsive';
import { uniqArray } from '@/utils/helpers/array.helpers';
import { useBookingStore } from '@/stores/bookingStore';
import { translate } from '@/i18n';

const weekDays = [
  {
    label: 'monday',
    day: 1,
  },
  {
    label: 'tuesday',
    day: 2,
  },
  {
    label: 'wensday',
    day: 3,
  },
  {
    label: 'thursday',
    day: 4,
  },
  {
    label: 'friday',
    day: 5,
  },
  {
    label: 'saturday',
    day: 6,
  },
  {
    label: 'sunday',
    day: 0,
  },
];

export const generateWeeklyDates = (days, interval) => {
  if (!interval || !interval.start || !interval.end) {
    return [];
  }

  const uniqueDays = uniqArray(days);

  return uniqueDays.map((day) => ({
    start: setDay(new Date(interval.start), day, { weekStartsOn: 1 }),
    end: setDay(new Date(interval.end), day, { weekStartsOn: 1 }),
  }));
};

const WeeklySelector = () => {
  const { setFieldValue, values } = useFormikContext<any>();
  const { isMobile } = useResponsive();
  const week = useBookingStore((state) => state.week);
  const days = values.days;
  const interval = values.weekly;

  const weekStart = startOfWeek(week, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(week, { weekStartsOn: 1 });
  const startBook =
    values.start || weekStart || startOfWeek(new Date(), { weekStartsOn: 1 });
  const endBook =
    values.end || weekEnd || endOfWeek(new Date(), { weekStartsOn: 1 });

  const handleDaySelect = (day: number) => {
    const isIncluded = days.includes(day);
    let newDays: any[] = [];

    if (isIncluded) {
      newDays = days.filter((d) => d !== day);
    } else {
      newDays = [...days, day].sort((a, b) => a - b);
    }

    const slots = generateWeeklyDates(newDays, interval);
    const weeks = eachWeekOfInterval(
      { start: startBook, end: endBook },
      { weekStartsOn: 1 },
    );
    const timeSlots = weeks.reduce((acc, currWeek) => {
      const weekSlots: any[] = [];
      slots.forEach((slot) => {
        weekSlots.push({
          start: setWeek(slot.start, getWeek(currWeek), { weekStartsOn: 1 }),
          end: setWeek(slot.end, getWeek(currWeek), { weekStartsOn: 1 }),
        });
      });
      return acc.concat(weekSlots);
    }, [] as any);

    setFieldValue('dates', timeSlots);
    setFieldValue('days', newDays);
  };

  useEffect(() => {
    const slots = generateWeeklyDates(days, interval);

    if (!startBook || !endBook) return;

    console.log(startBook, endBook);

    const weeks = eachWeekOfInterval(
      {
        start: startBook,
        end: isAfter(endBook, startBook) ? endBook : addDays(startBook, 7),
      },
      { weekStartsOn: 1 },
    );
    const timeSlots = weeks.reduce((acc, currWeek) => {
      const weekSlots: any[] = [];
      slots.forEach((slot) => {
        weekSlots.push({
          start: setWeek(slot.start, getWeek(currWeek), { weekStartsOn: 1 }),
          end: setWeek(slot.end, getWeek(currWeek), { weekStartsOn: 1 }),
        });
      });
      return acc.concat(weekSlots);
    }, [] as any);

    setFieldValue('dates', timeSlots);
  }, [interval]);

  if (!isMobile) return null;

  return (
    <Wrapper>
      <FormLabel>{translate('week-days')}</FormLabel>
      <DaysWrapper>
        {weekDays.map((day) => (
          <WeeklyDay
            key={day.day}
            onChange={handleDaySelect}
            day={day.day}
            days={days}
          >
            {translate(day.label)}
          </WeeklyDay>
        ))}
      </DaysWrapper>
      <TimeInputs>
        <WeeklyInterval />
      </TimeInputs>
    </Wrapper>
  );
};

type WeeklyDayProps = {
  onChange: (day: number) => void;
  day: number;
  days: number[];
};

const WeeklyDay: React.FC<WeeklyDayProps> = ({
  children,
  onChange,
  day,
  days,
}) => {
  const isActive = days.includes(day);

  return (
    <Day
      onClick={onChange.bind(null, day)}
      $active={isActive}
    >
      {children}
    </Day>
  );
};

export default WeeklySelector;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DaysWrapper = styled.div`
  display: flex;
`;

const Day = styled.div<{ $active?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;
  flex-shrink: 0;
  cursor: pointer;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #838d96;

  &:hover {
    color: #ffffff;
    background: #09a0af;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: #ffffff;
      background: #09a0af;
    `}
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const TimeInputs = styled.div`
  display: flex;
`;
