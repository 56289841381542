import { FC } from 'react';

interface IconProps {
  width?: number;
  height?: number;
}

const MdOutlineTune: FC<IconProps> = ({ width, height }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 5.63158H8.25M5.75 5.63158H2M22 18.7895H8.25M5.75 18.7895H2M15.75 12.2105H2M22 12.2105H18.25M7 3C7.33152 3 7.64946 3.13863 7.88388 3.38539C8.1183 3.63214 8.25 3.96682 8.25 4.31579V6.94737C8.25 7.29634 8.1183 7.63101 7.88388 7.87777C7.64946 8.12453 7.33152 8.26316 7 8.26316C6.66848 8.26316 6.35054 8.12453 6.11612 7.87777C5.8817 7.63101 5.75 7.29634 5.75 6.94737V4.31579C5.75 3.96682 5.8817 3.63214 6.11612 3.38539C6.35054 3.13863 6.66848 3 7 3ZM7 16.1579C7.33152 16.1579 7.64946 16.2965 7.88388 16.5433C8.1183 16.79 8.25 17.1247 8.25 17.4737V20.1053C8.25 20.4542 8.1183 20.7889 7.88388 21.0357C7.64946 21.2824 7.33152 21.4211 7 21.4211C6.66848 21.4211 6.35054 21.2824 6.11612 21.0357C5.8817 20.7889 5.75 20.4542 5.75 20.1053V17.4737C5.75 17.1247 5.8817 16.79 6.11612 16.5433C6.35054 16.2965 6.66848 16.1579 7 16.1579ZM17 9.57895C17.3315 9.57895 17.6495 9.71757 17.8839 9.96433C18.1183 10.2111 18.25 10.5458 18.25 10.8947V13.5263C18.25 13.8753 18.1183 14.21 17.8839 14.4567C17.6495 14.7035 17.3315 14.8421 17 14.8421C16.6685 14.8421 16.3505 14.7035 16.1161 14.4567C15.8817 14.21 15.75 13.8753 15.75 13.5263V10.8947C15.75 10.5458 15.8817 10.2111 16.1161 9.96433C16.3505 9.71757 16.6685 9.57895 17 9.57895Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default MdOutlineTune;
