import React, { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface RoundButtonProps {
	icon: ReactNode
	onClick: () => void
	isActive?: boolean
}

export const RoundButton: FC<RoundButtonProps> = ({
	icon,
	onClick: handleOnClick,
	isActive = false,
}) => {
	return (
		<ButtonWrap $isActive={isActive} onClick={handleOnClick}>
			{icon}
		</ButtonWrap>
	)
}

const ButtonWrap = styled.button<{ $isActive: boolean }>`
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  width: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
  border: none;
  cursor: pointer;
  color: #838d96;
  transition: 0.3s;

	${({ $isActive }) =>
		$isActive &&
		css`
			background: #2c2c2c;
			color: #fff;
		`}
`
