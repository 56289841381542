import React, { useEffect, useState } from 'react';
import { getIn, useField, useFormik, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { Input } from './Input';
import styled from 'styled-components';
import { ru } from 'date-fns/locale';
import SingleDatePicker from '@/components/layout/Sidebar/Reports/pickers/SingleDatePicker';
import { format } from 'date-fns';

export const SingleDatePickerField = ({ name, onChange, ...props }) => {
  const { setFieldValue, touched, errors } = useFormikContext();
  const [field] = useField(props.field);
  const errorMessage = getIn(touched, field.name) && getIn(errors, field.name);
  const [toggle, setOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<{
    startDate: any;
    endDate: any;
    key: string;
  }>({ startDate: new Date(), endDate: new Date(), key: 'selection' });

  useEffect(() => {
    setFieldValue(field.name, selection.startDate);
    setOpen(false);

    if (typeof onChange == 'function') {
      onChange(selection.startDate);
    }
  }, [selection]);

  return (
    <InputWrapper>
      <Input
        {...field}
        name={field.name}
        $fullWidth
        value={`${format(field.value, 'dd.MM.yyyy')}`}
        onClick={() => setOpen(true)}
        {...props}
      />
      <SingleDatePicker
        open={toggle}
        setOpen={setOpen}
        selection={selection}
        setSelection={setSelection}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputWrapper>
  );
};

export const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomDatePickerInput = styled(Input)`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.background.primary};
`;

export const ErrorMessage = styled.div`
  line-height: 24px;
  color: rgb(251, 105, 94);
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 5px;
  border-radius: 3px;
  transition: background-color 0.15s ease-in-out 0s;
`;
