import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import Elements from '@/components/Elements/Elements';
import Employees from '@/components/Employee/Employees';
import { useGlobalStore } from '@/stores/globalStore';
import Layers from '@/components/layout/Sidebar/Layers/Layers';
import Bookings from '@/components/layout/Sidebar/Bookings';
import Reports from '@/components/layout/Sidebar/Reports/Reports';
import Menu from '@/components/layout/Sidebar/Menu/Menu';
import { media } from '@/ui/media';

export type SectionType =
  | 'layers'
  | 'bookings'
  | 'elements'
  | 'employees'
  | 'reports';

const sectionsObj = {
  layers: <Layers />,
  bookings: <Bookings />,
  elements: <Elements />,
  employees: <Employees />,
  reports: <Reports />,
};

const getComponent = (section: SectionType | null) => {
  if (!section) return null;

  return React.cloneElement(sectionsObj[section]);
};

const SidebarContainer: React.FC = () => {
  const [section, setSection] = useState<null | SectionType>(null);
  const selector = useGlobalStore((state) => state.selector);

  const toggleSection = (targetSection: SectionType | null) =>
    section === targetSection ? null : targetSection;
  const handleSectionChange = (targetSection: SectionType | null) => {
    const target = toggleSection(targetSection);

    if (target) {
      api.start({
        to: { transform: 'translateX(-100%)' },
        onResolve: () => {
          setSection(target);

          api.start({
            to: async (next) => {
              // @ts-ignore
              await next({ transform: 'translateX(-100%)' });
              // @ts-ignore
              await next({ transform: 'translateX(0%)' });
            },
          });
        },
      });
    } else {
      api.start({
        to: { transform: 'translateX(-100%)' },
        onResolve: () => {
          setSection(target);
        },
      });
    }
  };

  const [style, api] = useSpring(() => ({
    // config: {
    //     easing: easePoly.exponent(2),
    // },
  }));

  useEffect(() => {
    handleSectionChange(selector);
  }, [selector]);

  return (
    <SidebarWrapper style={style}>
      <Wrapper>
        <Container>
          <Content>{getComponent(section)}</Content>
        </Container>
        <Menu />
      </Wrapper>
    </SidebarWrapper>
  );
};

export default memo(SidebarContainer);

const Content = styled.div`
  padding: 30px 40px;
  min-width: 330px;
  position: relative;
  max-height: 100%;
  height: 100%;

  ${media.lg`
        padding: 10px;
        min-width: auto;
        max-width: 100%;
    `}
`;

const Container = styled.div`
  height: 100%;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: width 0.5s;
  z-index: 1;
  background: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.text.primary};
`;

const Wrapper = styled.div`
  height: 100%;
  /* padding-top: 60px; */
  position: relative;
  z-index: 10;
  width: 100%;

  ${media.lg`
        width: 100%;
    `}
`;

const SidebarWrapper = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 75px;
  bottom: 0;
  display: flex;
  align-items: center;
  max-width: 1200px;
  transform: translateX(-100%);
  z-index: 1001;

  ${media.lg`
        width: 100%;
    `}
`;
