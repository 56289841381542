import React from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './locales';
import translations from './translations';

const LocaleProvider = ({ children, locale = LOCALES.ENGLISH }) => {
  return (
    <IntlProvider
      locale={locale}
      textComponent={React.Fragment}
      messages={translations[locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default LocaleProvider;
