import media from '@/ui/media';
import styled, { css } from 'styled-components';

export const ButtonBase = styled.button<{ $fullWidth?: boolean }>`
    border: none;
    outline: none;
    font-family: 'Nunito', sans-serif;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    padding: 10px 20px;
    cursor: pointer;

    min-height: 40px;

    &:disabled {
        opacity: 0.7;
    }

    ${media.md`
        font-size: 14px;
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;
