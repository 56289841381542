import React, { useEffect, useMemo, useState } from 'react';
import { Input } from '@/ui/components/Field/Input';
import { Grid } from '@/components/Grid';
import ElementsListItem from './ElementsListItem';
import Toolbar from '../Toolbar/Toolbar';
import { SelectInput } from '@/ui/components/Field/Select';
import Pagination from '@/ui/components/Pagination/Pagination';
import { useNodes } from '@/api/hooks/useNodes';
import { debounce } from 'lodash';
import { useProjectStore } from '@/stores/projectStore';
import { useMetadata } from '@/api/hooks/useMetadata';
import Sidebar from '../layout/Sidebar/Sidebar';
import useResponsive from '@/hooks/useResponsive';
import useSorting from '@/hooks/useSorting';
import { TableCell } from '@/components/Employee/Employees';
import { translate } from '@/i18n';
import { useIntl } from 'react-intl';

const Elements = () => {
  const nodes = useProjectStore((state) => state.nodes);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [parent, setParent] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [name, setName] = useState<string>('');
  const { sort, direction, handleSort } = useSorting();

  const { data, isLoading } = useNodes({
    page: currentPage - 1,
    name,
    parent,
    type,
    sort,
    direction,
  });
  const { metadata } = useMetadata();
  const intl = useIntl();

  const handleNameChange = (e) => setName(e?.target?.value);
  const handleParentChange = (e) => setParent(e?.target?.value);
  const handleTypeChange = (e) => setType(e?.target?.value);
  const { isDesktop } = useResponsive();

  const debouncedNameResponse = useMemo(() => {
    return debounce(handleNameChange, 500);
  }, []);

  useEffect(() => {
    return () => debouncedNameResponse.cancel();
  }, []);

  return (
    <Sidebar minWidth={900}>
      <Sidebar.Header title="objects" />

      <Toolbar>
        <Toolbar.Item
          xs={12}
          md={4}
        >
          <Toolbar.Label>{translate('search-by-name')}</Toolbar.Label>
          <Input
            $fullWidth
            placeholder={intl.formatMessage({ id: 'search' })}
            onChange={debouncedNameResponse}
          />
        </Toolbar.Item>
        <Toolbar.Item
          xs={6}
          md={4}
        >
          <Toolbar.Label>{translate('position')}</Toolbar.Label>
          <SelectInput
            $fullWidth
            placeholder="Выберите расположение"
            value={parent}
            onChange={handleParentChange}
          >
            <option value="">{translate('all-levels')}</option>
            {nodes.map((node) => (
              <option
                key={node.id}
                value={node.id}
              >
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
        <Toolbar.Item
          xs={6}
          md={4}
        >
          <Toolbar.Label>{translate('object-types')}</Toolbar.Label>
          <SelectInput
            $fullWidth
            placeholder="Выберите тип места"
            value={type}
            onChange={handleTypeChange}
          >
            <option value="">{translate('all')}</option>
            {metadata?.nodes?.map((node) => (
              <option
                key={node.uid}
                value={node.uid}
              >
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
      </Toolbar>

      <Grid>
        {isDesktop && (
          <Grid.RowHeader $cols="2fr 1fr 1fr 30px 30px">
            <TableCell
              onClick={handleSort.bind(null, 'name')}
              $active={sort === 'name'}
              $direction={direction}
            >
              {translate('object-name')}
            </TableCell>
            <TableCell
              onClick={handleSort.bind(null, 'parent')}
              $active={sort === 'parent'}
              $direction={direction}
            >
              {translate('position')}
            </TableCell>
            <TableCell
              onClick={handleSort.bind(null, 'type_name')}
              $active={sort === 'type_name'}
              $direction={direction}
            >
              {translate('type')}
            </TableCell>
            <Grid.Item />
            <Grid.Item />
          </Grid.RowHeader>
        )}

        {data?.items.map((spot) => (
          <ElementsListItem
            key={spot.id}
            item={spot}
          />
        ))}
      </Grid>

      <Pagination
        currentPage={currentPage}
        total={data?.total || 0}
        handlePageChange={setCurrentPage}
      />
    </Sidebar>
  );
};

export default Elements;
