import React from 'react';
import { Grid } from '@/components/Grid';
import viewImage from '@/images/view.png';
import { useGlobalStore } from '@/stores/globalStore';
import InfoIcon from '../icons/InfoIcon';
import useResponsive from '@/hooks/useResponsive';
import MobileEmployeeItem from './MobileEmployeeItem';
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers';

export const EmployeeMobileListItem: React.FC<any> = ({ user }) => {
  const { setEmployee } = useGlobalStore();
  const { id, display, fields } = user;

  const openUser = () => setEmployee(id);

  return (
    <MobileEmployeeItem
      name={display}
      fields={fields}
      openUser={openUser}
    />
  );
};

const EmployeeListItem: React.FC<any> = ({ template, columns, item }) => {
  const { setEmployee } = useGlobalStore();
  const { id, data } = item;

  const openUser = () => setEmployee(id);

  return (
    <Grid.Row $cols={template}>
      {data.map(({ label, value, type }, idx) => (
        <Grid.Item key={idx}>
          {type === 'phone' ? formatPhoneNumber(value) : value}
        </Grid.Item>
      ))}
      {/* <Grid.Item>{item.id}</Grid.Item>
            <Grid.Item>{item.name}</Grid.Item>

            <Grid.Item>{item.department || 'Не указано'}</Grid.Item>
            <Grid.Item>{item.email}</Grid.Item>
            <Grid.Item>{item.phone}</Grid.Item> */}
      <Grid.Item>
        <a
          href="#"
          onClick={openUser}
        >
          <InfoIcon />
        </a>
      </Grid.Item>
    </Grid.Row>
  );
};

export default EmployeeListItem;
