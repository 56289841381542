import { BookingItem } from '@/api/bookings';
import { useBookings } from '@/api/hooks/useBookings';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import {
  ControlsWrapper,
  FormControls,
} from '@/components/ui/form/FormControls';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { bookingTypeTranslate } from '../../Bookings';
import Pagination from '@/ui/components/Pagination/Pagination';
import useResponsive from '@/hooks/useResponsive';
import { format, parseISO } from 'date-fns';
import { IObjectType, useProjectStore } from '@/stores/projectStore';
import { formatToReport } from '@/utils/helpers/dates.helpers';
import { CSVLink } from 'react-csv';
import { printPDF } from '@/utils/func/print';
import MobileReportItem from './MobileReportItem';
import { useNodes } from '@/api/hooks/useNodes';
import { NodeElement } from '@/api/project';
import { TreeNodeData } from '@/components/Elements/tree';
import { useIntl } from 'react-intl';
import { translate } from '@/i18n';

type IColumn = {
  label: string;
  key: string;
  alias: string;
};

const bookingHeaders: IColumn[] = [
  { label: 'Место', key: 'name', alias: 'object' },
  { label: 'Тип места', key: 'objectType', alias: 'object-type' },
  { label: 'Расположение', key: 'layer', alias: 'location' },
];

const ObjectsReportData = ({ settings, onClose, objectTypes, ...props }) => {
  const { data, isLoading } = useNodes({
    page: 1,
    perPage: 1000,
    parent: settings.layer,
    type: settings.objectType,
  });

  const nodes = useProjectStore((state) => state.nodes);
  const intl = useIntl();

  const { isDesktop } = useResponsive();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const columns = bookingHeaders
    .filter((header) => settings.cols.includes(header.key))
    .map((item) => ({
      ...item,
      label: intl.formatMessage({ id: item.alias }),
    }));
  const pdfCols = useMemo(
    () => columns.map((col) => ({ header: col.label, dataKey: col.key })),
    [columns],
  );
  const dataItems =
    useMemo(
      () =>
        data?.items.map((item) =>
          ElementsMapper.toExport(item, columns, nodes),
        ),
      [data],
    ) || [];

  return (
    <ReportDataContainer>
      <Table>
        {isDesktop && (
          <TableHead>
            <tr>
              {columns.map((header) => (
                <TableHeadCell key={header.key}>{header.label}</TableHeadCell>
              ))}
            </tr>
          </TableHead>
        )}

        <TableBody>
          {data?.items && data?.items.length ? (
            data?.items
              .slice((currentPage - 1) * 20, currentPage * 20)
              .map((item) => (
                <ElementItem
                  key={item.id}
                  columns={columns}
                  nodes={nodes}
                  item={item}
                />
              ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <NotFound>{translate('no-results')}</NotFound>
              </td>
            </tr>
          )}
        </TableBody>
      </Table>

      <Pagination
        inverse
        currentPage={currentPage}
        total={data?.items.length || 0}
        handlePageChange={setCurrentPage}
      />

      <ControlsWrapper>
        <FormControls>
          <PrimaryButton
            type="button"
            onClick={() =>
              printPDF({
                name:
                  intl.formatMessage({ id: 'create-report-' + settings.type }) +
                  ` ${formatToReport(new Date())}`,
                columns: pdfCols,
                body: dataItems,
              })
            }
          >
            {translate('save-pdf')}
          </PrimaryButton>
          <CSVLink
            data={dataItems}
            headers={columns}
            separator={';'}
            filename={
              intl.formatMessage({ id: 'create-report-' + settings.type }) +
              ` ${formatToReport(new Date())}`
            }
          >
            <PrimaryButton type="button">{translate('save-csv')}</PrimaryButton>
          </CSVLink>
          <DefaultButton
            type="button"
            onClick={onClose}
          >
            {translate('cancel')}
          </DefaultButton>
        </FormControls>
      </ControlsWrapper>
    </ReportDataContainer>
  );
};

export default ObjectsReportData;

const ReportDataContainer = styled.div`
  margin-top: 32px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 5px 10px 5px 0;
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const Table = styled.table`
  margin: 1rem 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  tr td:last-child {
    width: 1%;
    white-space: nowrap;
  }
`;

const TableCell = styled.td`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  padding: 5px 10px 5px 0;
`;

const ElementItem: React.FC<{
  item: NodeElement;
  columns: IColumn[];
  nodes: TreeNodeData[];
}> = ({ item, columns, nodes }) => {
  const { isDesktop } = useResponsive();

  const mappedExport = ElementsMapper.toExport(item, columns, nodes);
  const mapped = ElementsMapper.toReport(item, columns, nodes);

  if (!isDesktop)
    return (
      <MobileReportItem
        columns={columns}
        data={mappedExport}
      />
    );

  return (
    <tr>
      {mapped.map((col) => (
        <TableCell key={col?.value}>{col?.value}</TableCell>
      ))}
    </tr>
  );
};

export class ElementsMapper {
  public static toExport(
    item: NodeElement,
    columns: IColumn[],
    nodes: TreeNodeData[],
  ) {
    if (!columns) return [];

    let data = {};

    columns.forEach((col) => {
      const translated = propsMapperDictionary[col.key];

      if (!translated) return null;

      let candidate = item[translated.value];

      if (!candidate) return null;

      if (translated.type === 'layerId') {
        const parentId = item[translated.value];
        const node = nodes.find((n) => n.id == parentId);
        data[col.key] = node?.name;
        return;
      }

      data[col.key] = candidate;
      return;
    });

    return data;
  }

  public static toReport(
    item: NodeElement,
    columns: IColumn[],
    nodes: TreeNodeData[],
  ) {
    if (!columns) return [];

    const fields = columns.map((col) => {
      const translated = propsMapperDictionary[col.key];

      if (!translated) return null;

      let candidate = item[translated.value];

      if (!candidate) return null;

      if (translated.type === 'layerId') {
        const parentId = item[translated.value];
        const node = nodes.find((n) => n.id == parentId);
        return {
          value: node?.name,
          type: translated.type,
        };
      }

      return {
        value: candidate,
        type: translated.type,
      };
    });

    return fields;
  }
}

const propsMapperDictionary = {
  name: {
    value: 'name',
    type: 'string',
  },
  objectType: {
    value: 'type_name',
    type: 'string',
  },
  layer: {
    value: 'parent',
    type: 'layerId',
  },
};

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`;
