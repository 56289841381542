import { FormLabel } from '@/ui/components/Form/FormLabel';
import Grid from '@/ui/components/Grid';
import React from 'react';
import styled from 'styled-components';
import { Field, useFormikContext } from 'formik';
import RemoveIcon from '@/components/icons/RemoveIcon';
import { DatePickerField } from '@/ui/components/Field/DatePickerField';
import PlusIcon from '@/components/icons/PlusIcon';
import {
	addMinutes,
	getHours,
	getMinutes,
	isAfter,
	isEqual,
	isSameDay,
	setHours,
	setMinutes,
} from 'date-fns'
import useResponsive from '@/hooks/useResponsive'
import media from '@/ui/media'
import { translate } from '@/i18n'
import CalendarIcon from '@/components/icons/CalendarIcon'

const BookingInterval = ({ name, interval, index, count, helpers, type }) => {
	const { setFieldValue, values } = useFormikContext<any>()
	const start = interval.start ? addMinutes(interval.start, 30) : null
	const { isDesktop } = useResponsive()

	const isItSame = interval.end ? isSameDay(interval.start, interval.end) : true
	const minHours = start ? getHours(start) : 0
	const minMinutes = start ? getMinutes(start) : 0
	const minTime = isItSame
		? setHours(setMinutes(new Date(), minMinutes), minHours)
		: setHours(setMinutes(new Date(), 0), 0)

	return (
		<IntervalContainer key={'dates ' + index}>
			<Grid container>
				<Grid item xs={12} md={8}>
					<IntervalGrid>
						<IntervalBox>
							<FormLabel>{translate('start-datetime')}</FormLabel>
								<Field
									name={`${name}[${index}].start`}
									component={DatePickerField}
									$fullWidth
									onFocus={() => console.log('focus')}
									InputProps={{
										endAdornment: (
											<CalendarIcon />
										)
									}}
									onChange={(val) => {
										const isIntervalAfter =
											interval.end && val ? isAfter(val, interval.end) : false
										const equality =
											interval.end && val ? isEqual(val, interval.end) : false

										if (isIntervalAfter || equality) {
											setFieldValue(
												`${name}[${index}].end`,
												addMinutes(val, 30),
											)
										}

										setFieldValue(`${name}[${index}].start`, val)
									}}
								/>
						</IntervalBox>
						<IntervalBox>
							<FormLabel>{translate('end-datetime')}</FormLabel>
								<Field
									$fullWidth
									name={`${name}[${index}].end`}
									type="text"
									placeholder="Время окончания"
									// selected={start}
									openToDate={start}
									minDate={start || null}
									minTime={minTime}
									maxTime={setHours(setMinutes(new Date(), 59), 23)}
									component={DatePickerField}
									onChange={(val) => {
										const isIntervalAfter =
											interval.start && val
												? isAfter(val, interval.start)
												: false
										const equality =
											interval.start && val
												? isEqual(val, interval.start)
												: false

										console.log(isIntervalAfter, equality)

										if (interval.start && (equality || !isIntervalAfter)) {
											setFieldValue(
												`${name}[${index}].end`,
												addMinutes(interval.start, 30),
											)
										} else {
											setFieldValue(`${name}[${index}].end`, val)
										}
									}}
								/>
						</IntervalBox>
						<Buttons>
							{count > 0 && (
								<BtnWrapper type="button" onClick={() => helpers.remove(index)}>
									<RemoveIcon color="#fff" />
								</BtnWrapper>
							)}
							{isDesktop && (
								<BtnWrapper
									type="button"
									onClick={() => helpers.insert(index, { start: '', end: '' })}
								>
									<PlusIcon color="#fff" />
								</BtnWrapper>
							)}
						</Buttons>
					</IntervalGrid>
				</Grid>
			</Grid>
		</IntervalContainer>
	)
}

export const NoBookingIntervals = ({ helpers }) => {
	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<AddButton
					type="button"
					onClick={() => helpers.push({ start: '', end: '' })}
				>
					<BtnWrapper as="div">
						<PlusIcon color="#fff" />
					</BtnWrapper>
					<BtnText>{translate('add-interval')}</BtnText>
				</AddButton>
			</Grid>
		</Grid>
	)
}

export default BookingInterval

const FieldWrap = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: 20px;
		transform: translateY(-50%);
		right: 10px;
		width: 20px;
		height: 20px;
		color: #828282;
		pointer-events: none;
	}
`

const IntervalGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr auto;
	gap: 16px;
	width: 100%;
`

const IntervalContainer = styled.div`
	display: flex;
	width: 100%;
`

const IntervalBox = styled.div`
	display: flex;
	/* justify-content: space-between; */
	flex-direction: column;
	height: 100%;
`

const Buttons = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	margin-top: 24px;
	flex-shrink: 0;
	/* align-items: center; */
`

const BtnWrapper = styled.button`
	outline: none;
	border: none;
	cursor: pointer;
	background: #079dac;
	border-radius: 50%;
	width: 34px;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s;

	&:not(:last-child) {
		margin-right: 8px;
	}

	&:hover {
		opacity: 0.7;
	}
`

const BtnText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 32px;
	margin-left: 12px;
	cursor: pointer;
`

const AddButton = styled.button`
	border: none;
	outline: none;
	background: transparent;
	display: flex;
	align-items: center;

	${media.sm`
        width: 100%;
        padding-top: 6px;
    `}
`;
