import React from 'react'

const CalendarIcon = () => {
	return (
		<svg
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 1.39941C8 1.15077 7.90123 0.912317 7.72541 0.736501C7.5496 0.560686 7.31114 0.461914 7.0625 0.461914C6.81386 0.461914 6.5754 0.560686 6.39959 0.736501C6.22377 0.912317 6.125 1.15077 6.125 1.39941V2.33691H4.25C3.25544 2.33691 2.30161 2.732 1.59835 3.43526C0.895088 4.13853 0.5 5.09235 0.5 6.08691L0.5 7.96191H30.5V6.08691C30.5 5.09235 30.1049 4.13853 29.4016 3.43526C28.6984 2.732 27.7446 2.33691 26.75 2.33691H24.875V1.39941C24.875 1.15077 24.7762 0.912317 24.6004 0.736501C24.4246 0.560686 24.1861 0.461914 23.9375 0.461914C23.6889 0.461914 23.4504 0.560686 23.2746 0.736501C23.0988 0.912317 23 1.15077 23 1.39941V2.33691H8V1.39941ZM30.5 26.7119V9.83691H0.5V26.7119C0.5 27.7065 0.895088 28.6603 1.59835 29.3636C2.30161 30.0668 3.25544 30.4619 4.25 30.4619H26.75C27.7446 30.4619 28.6984 30.0668 29.4016 29.3636C30.1049 28.6603 30.5 27.7065 30.5 26.7119ZM20.8512 17.0632L15.2262 22.6882C15.1392 22.7755 15.0357 22.8447 14.9218 22.892C14.8079 22.9393 14.6858 22.9636 14.5625 22.9636C14.4392 22.9636 14.3171 22.9393 14.2032 22.892C14.0893 22.8447 13.9858 22.7755 13.8988 22.6882L11.0863 19.8757C10.9102 19.6996 10.8113 19.4609 10.8113 19.2119C10.8113 18.963 10.9102 18.7242 11.0863 18.5482C11.2623 18.3721 11.501 18.2732 11.75 18.2732C11.999 18.2732 12.2377 18.3721 12.4137 18.5482L14.5625 20.6988L19.5238 15.7357C19.6998 15.5596 19.9385 15.4607 20.1875 15.4607C20.4365 15.4607 20.6752 15.5596 20.8512 15.7357C21.0273 15.9117 21.1262 16.1505 21.1262 16.3994C21.1262 16.6484 21.0273 16.8871 20.8512 17.0632Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default CalendarIcon
