import React, { useState } from 'react';

const useSorting = () => {
  const [sort, setSort] = useState<string>('');
  const [direction, setDirection] = useState<0 | 1>(0);

  const handleSort = (newSort) => {
    if (newSort === sort) {
      setDirection(direction ? 0 : 1);
    } else {
      setDirection(0);
      setSort(newSort);
    }
  };

  return {
    sort,
    direction,
    handleSort,
  };
};

export default useSorting;
