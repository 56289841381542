import SearchIcon from '@/components/icons/SearchIcon';
import { useGlobalStore } from '@/stores/globalStore';
import { debounce } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface SearchFieldProps {
  value: string;
  onChange: (value: string) => void;
}

const SearchField: FC<SearchFieldProps> = ({ value = '', onChange }) => {
  const setSelector = useGlobalStore((state) => state.setSelector);
  const handleClose = () => setSelector(null);
  const [search, setSearch] = useState(value);

  const handleOnChange = (e) => {
    const value = e?.target?.value || '';
    setSearch(value);
  };

  const debouncedResponse = useMemo(() => {
    return debounce((value) => {
      onChange(value);
    }, 500);
  }, []);

  useEffect(() => {
    debouncedResponse(search);
  }, [search]);

  useEffect(() => {
    return () => debouncedResponse.cancel();
  }, []);

  return (
    <SearchWrapper onClick={handleClose}>
      <FormattedMessage id="search">
        {(placeholder) => (
          <SearchInput
            placeholder={placeholder + '...'}
            onChange={handleOnChange}
            value={search}
          />
        )}
      </FormattedMessage>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </SearchWrapper>
  );
};

export default SearchField;

const SearchWrapper = styled.div`
	height: 40px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #000;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	padding: 0px 10px;
	display: flex;
	align-items: center;
	width: 100%;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;

	font-weight: 400;
	font-size: 1.6rem;
	line-height: 3.2rem;
	color: #000000;

	&::placeholder {
		color: #2C2C2C;
		opacity: .5;
	}
`

const IconWrapper = styled.div`
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	align-items: center;
	color: #000;
`
