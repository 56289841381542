import React from 'react';
import styled, { css } from 'styled-components';
import { create } from 'zustand';
import Close from './Close';
import Modal from './Modal/Modal';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { FormControls } from './ui/form/FormControls';
import { translate } from '@/i18n';

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
  title: '',
  message: '',
  component: undefined,
  onSubmit: undefined,
  confirmBtnText: '',
  cancelBtnText: '',
  onClose: () => {
    set({
      onSubmit: undefined,
    });
  },
}));

export const confirmDialog = ({
  title,
  message,
  component,
  onSubmit,
  confirmBtnText = 'delete',
  cancelBtnText = 'cancel',
}: ConfirmDialogProps) => {
  useConfirmDialogStore.setState({
    title,
    message,
    component,
    onSubmit,
    confirmBtnText,
    cancelBtnText,
  });
};

const ConfirmDialog: React.FC = () => {
  const message = useConfirmDialogStore((state) => state.message);
  const onClose = useConfirmDialogStore((state) => state.onClose);
  const onSubmit = useConfirmDialogStore((state) => state.onSubmit);
  const component = useConfirmDialogStore((state) => state.component);
  const title = useConfirmDialogStore((state) => state.title);
  const confirmBtnText = useConfirmDialogStore((state) => state.confirmBtnText);
  const cancelBtnText = useConfirmDialogStore((state) => state.cancelBtnText);

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (typeof onSubmit === 'function') {
      onSubmit();
    }
    onClose();
  };

  return (
    <Modal
      isOpen={Boolean(onSubmit)}
      onClose={onClose}
      maxWidth={540}
    >
      <Header>
        <Title>{title}</Title>
        <Close
          color="#000"
          onClick={onClose}
        />
      </Header>

      <div>{component ? component : <ConfirmText>{message}</ConfirmText>}</div>

      <FormControls>
        <PrimaryButton
          $fullWidth
          onClick={handleSubmitClick}
          type="button"
        >
          {translate(confirmBtnText)}
        </PrimaryButton>
        <DefaultButton
          $fullWidth
          onClick={onClose}
          type="button"
        >
          {translate(cancelBtnText)}
        </DefaultButton>
      </FormControls>
    </Modal>
  );
};

export default ConfirmDialog;

type ConfirmDialogStore = {
  title: string;
  message?: string;
  component?: JSX.Element;
  onSubmit?: (...args) => void;
  onClose: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
};

type ConfirmDialogProps = {
  title: string;
  message?: string;
  component?: JSX.Element;
  onSubmit?: (...args) => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
};

const ConfirmText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const ButtonBaseStyles = css`
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 1.2rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
`;

export const BookButton = styled.button`
  ${ButtonBaseStyles};

  background: #044d8c;
  color: #f8dc4d;
  margin-right: 20px;
`;

export const CancelButton = styled.button`
  ${ButtonBaseStyles};

  background: #bfbfbf;
  color: #fff;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  color: #2c2c2c;
`;
