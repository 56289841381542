import React, { useState } from 'react';
import styled from 'styled-components';
import ExportIcon from '@/components/icons/ExportIcon';
import view from '@/images/view.png';
import ReportModal from './ReportModal';
import InfoIcon from '@/components/icons/InfoIcon';
import SquareReportModal from './SquareReports';
import EmptySeatsReportModal from './EmptySeats';
import BookingsReportModal from './reports/BookingsReport';
import { translate } from '@/i18n';

type ReportsItemProps = {
  item: {
    name: string;
    key: string;
  };
};

const ReportsItems: React.FC<ReportsItemProps> = ({ item }) => {
  const [report, setReport] = useState<string | null>(null);

  return (
    <>
      <ReportWrapper>
        <ReportName>{translate(item.key)}</ReportName>
        <ReportControls>
          {/* <ReportControlIconButton onClick={() => setReport('parking')}>
                        <ExportIcon />
                    </ReportControlIconButton> */}
          <ReportControlButton onClick={() => setReport(item.key)}>
            <InfoIcon />
          </ReportControlButton>
        </ReportControls>
      </ReportWrapper>
      {item.key === 'bookings-report' && (
        <BookingsReportModal
          report={report}
          item={item}
          onClose={() => setReport(null)}
        />
      )}
      {item.key === 'place-report' && (
        <ReportModal
          report={report}
          item={item}
          onClose={() => setReport(null)}
        />
      )}
      {item.key === 'square-report' && (
        <SquareReportModal
          report={report}
          item={item}
          onClose={() => setReport(null)}
        />
      )}
      {item.key === 'empty' && (
        <EmptySeatsReportModal
          report={report}
          item={item}
          onClose={() => setReport(null)}
        />
      )}
    </>
  );
};

export default ReportsItems;

const ReportWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;

const ReportName = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-right: 1.6rem;
  color: #ffffff;
`;

const ReportControls = styled.div`
  display: flex;
  align-items: center;
`;

const ReportControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const ReportControlIconButton = styled(ReportControlButton)`
  width: 30px;
  height: 30px;
  background: #f8dc4d;
  border-radius: 50%;
`;
