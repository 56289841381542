import styled, { css } from 'styled-components';
import React from 'react';

const GridTable = styled.div<{ $grow?: boolean }>`
  margin: 1rem 0;
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  ${({ $grow }) =>
    $grow &&
    css`
      flex-grow: 1;
    `}
`;

const GridItem = styled.div<{ $bold?: boolean }>`
  display: flex;
  align-items: center;
  /* word-break: break-all; */
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: bold;
    `};
`;

const GridRow = styled.div<{ $cols?: string; $label?: boolean }>`
  display: grid;
  padding: 0.4rem 0;
  ${({ $cols }) =>
    $cols
      ? css`
          grid-template-columns: ${$cols};
        `
      : css`
          grid-template-columns: 2fr 2fr 2fr 1fr;
        `};
  column-gap: 2rem;
  ${({ $label }) =>
    $label &&
    css`
      cursor: pointer;
    `};
`;
const GridHeader = styled(GridRow)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const GridLine = styled(GridRow)`
  min-height: 40px;
`;

export const Grid = ({ children, grow = false, ...otherProps }) => {
  return (
    <GridTable
      $grow={grow}
      {...otherProps}
    >
      {children}
    </GridTable>
  );
};

Grid.Item = GridItem;
Grid.RowHeader = GridHeader;
Grid.Row = GridRow;
Grid.Line = GridLine;

export default Grid;
