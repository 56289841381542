import React from 'react';

const RemoveIcon = ({ color = '#000' }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="2.42436"
        height="14.5462"
        rx="1"
        transform="matrix(-0.707108 0.707106 -0.707108 -0.707106 11.9999 10.2856)"
        fill={color}
      />
      <rect
        width="2.42436"
        height="14.5462"
        rx="1"
        transform="matrix(-0.707108 -0.707106 0.707108 -0.707106 1.71423 12)"
        fill={color}
      />
    </svg>
  );
};

export default RemoveIcon;
