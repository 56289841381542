import React from 'react';
import styled, { css } from 'styled-components';

export const FormLabel = styled.label<{ $alternative?: boolean }>`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  display: block;
  ${({ $alternative }) =>
    $alternative
      ? css`
          color: ${(props) => props.theme.palette.text.label};
        `
      : css`
          color: ${(props) => props.theme.palette.text.secondary};
        `}
  margin-bottom: 8px;
`;
