import axios, {
  AxiosHeaders,
  AxiosRequestHeaders,
  RawAxiosRequestHeaders,
} from 'axios';
import qs from 'query-string';
import { bookingsAPI } from './bookings';
import { layerAPI } from './layer';
import { projectAPI } from './project';
import { userAPI } from './user';
import $config from '@/utils/Config';

export const BASE_DOMAIN = $config.apiUrl;

export const redirectSAML = (
  authRef: string,
  wId?: number,
  pId?: number,
  single?: boolean,
) => {
  const queryParams = qs.parse(location.search);

  const workspaceId = wId || queryParams.workspace_id;
  const projectId = pId || queryParams.project_id;

  let to = single
    ? `${location.origin}/project/${Number(workspaceId)}/${Number(projectId)}`
    : location.origin;

  if (location.href.includes('/project')) {
    to = location.href;
  }

  location.href = authRef + `&lmreturnto=${to}`;
};
export const api = axios.create({
  baseURL: BASE_DOMAIN,
  withCredentials: $config.withCredentials,
  headers: {
    'x-ws-common-auth': localStorage.getItem('token') || '',
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token') || '';
  const headers = new AxiosHeaders({ 'x-ws-common-auth': token });
  config.headers = headers;

  return config;
});

api.interceptors.response.use(async (response) => {
  const token = response.data?.ws_auth?.wst || '-invalid-';

  // const status = response.data?.status
  // const authRef = response.data?.authref

  // Redirect to SAML auth
  // if (status == StatusResponseEnum.NotAuthorized && authRef) {
  //   const queryParams = qs.parse(location.search)

  //   const workspaceId = queryParams.workspace_id
  //   const projectId = queryParams.project_id

  //   let to = `${location.origin}/project/${Number(workspaceId)}/${Number(projectId)}`

  //   if (location.href.includes('/project')) {
  //     to = location.href
  //   }

  //   location.href = authRef + `&lmreturnto=${to}`

  //   return
  // }

  if (token != '-invalid-') {
    localStorage.setItem('token', token);
  }

  return response;
});

export const API = {
  user: userAPI,
  projects: projectAPI,
  bookings: bookingsAPI,
  layer: layerAPI,
};

export enum StatusResponseEnum {
  Success = 'ok',
  Error = 'error',
  NotAuthorized = 'auth',
}

export type ApiResponseType = {
  status: StatusResponseEnum;
  controller: string;
  authref?: string;
  authorization: any;
  error_info: {
    message: string;
    place: string;
    trace: string;
  };
  ws_auth: {
    wst: string;
  };
  // project_access: string
  // project_user_data: {
  //     role: string
  //     plugin_data: {
  //         [key: string]: any
  //     }
  // },
};
