import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';
import { useTransition, animated } from '@react-spring/web';
import { easePoly } from 'd3-ease';

interface PreloaderProps {
  show: boolean;
}

const Preloader: React.FC<PreloaderProps> = ({ show }) => {
  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: show,
    config: {
      easing: easePoly.exponent(2),
    },
  });

  return transitions(
    (styles, item) =>
      item && (
        <Wrapper style={styles}>
          <Loader />
        </Wrapper>
      ),
  );
};

export default Preloader;

const Wrapper = styled(animated.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
`;
