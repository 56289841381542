import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { UserService } from '../services/user.service';

export const useUserCardFields = () => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();

  return useQuery(
    ['runtime_fields', workspaceId, projectId],
    () =>
      UserService.getRuntimeFields({
        workspaceId: Number(workspaceId),
        projectId: Number(projectId),
      }),
    {
      enabled: !!(workspaceId && projectId),
      refetchOnMount: false,
      select: ({ data }) => ({ fields: data?.required_fields || [] }),
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить список дополнительных полей',
          },
          { variant: 'error' },
        );
      },
    },
  );
};
