import React from 'react';

const MinusIcon = ({ color = '#079DAC' }) => {
  return (
    <svg
      width="17"
      height="3"
      viewBox="0 0 17 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.167786"
        y="2.99353"
        width="2.39666"
        height="16.7766"
        rx="1.19833"
        transform="rotate(-90 0.167786 2.99353)"
        fill={color}
      />
    </svg>
  );
};

export default MinusIcon;
