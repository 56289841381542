import { useTree } from '@/api/hooks/useTree';
import { OfficeMapIcon } from '@/components/icons';
import BackIcon from '@/components/icons/BackIcon';
import { layerChangeEvent } from '@/components/layout/AppBar/search/SearchItem';
import { SectionType } from '@/containers/SidebarContainer';
import { translate } from '@/i18n';
import { useGlobalStore } from '@/stores/globalStore';
import { RolesEnum, useUserStore } from '@/stores/userStore';
import media from '@/ui/media';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import SearchBar from './search/SearchBar';
import UserBar from './user/UserBar';
import { RoundButton } from './RoundButton';
import MdOutlineTune from '@/components/icons/MdOutlineTune';
import { useMapStore } from '@/stores/mapStore';
import useResponsive from '@/hooks/useResponsive';

const Appbar: React.FC = () => {
  const { isMobile } = useResponsive()
  const role = useUserStore((state) => state.role);
  const [mapDateIsVisible, toggleVisibleMapDate] = useMapStore((state) => [
    state.mapDateIsVisible,
    state.toggleVisibleMapDate,
  ]);
  const isAdmin = role === RolesEnum.Admin;

  const handleOnClickByMoreButton = () => {
    toggleVisibleMapDate();
  };

	return (
		<Wrapper>
			{isAdmin ? (
				<>
					<GridWrapper>
						<BackItem name={translate('appbar.back')} icon={BackIcon} />
            {isMobile ?
						<RoundButton
							icon={<MdOutlineTune />}
							onClick={handleOnClickByMoreButton}
							isActive={mapDateIsVisible}
						/> : null
            }
					</GridWrapper>
					<SearchWrapper>
						<SearchBar />
					</SearchWrapper>
					<UserBar />
				</>
			) : (
				<>
					<MenuWrapper>
						<LayerItem
							name={translate('appbar.office-plan')}
							selector="layers"
							icon={OfficeMapIcon}
						/>
						<BackItem name={translate('appbar.back')} icon={BackIcon} />
					</MenuWrapper>
					<SearchWrapper>
						<SearchBar />
					</SearchWrapper>
					<UserBar />
				</>
			)}
		</Wrapper>
	)
}

export default Appbar;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const BackItem = ({ name, icon: Icon }) => {
  const { layers } = useTree();
  const { activeLayer, setActiveLayer } = useGlobalStore();
  const { nodes } = layers || {};
  const current = useMemo(
    () => nodes?.find((node) => node.id == activeLayer),
    [nodes, activeLayer],
  );

  const handleLayerChange = () => {
    if (!current || !current.parent) return;
    document.dispatchEvent(layerChangeEvent(current.parent));

    setActiveLayer(Number(current.parent));
  };

  if (!current || !current.parent) return null;

  return (
    <Item onClick={handleLayerChange}>
      <Icon />
      <p>{name}</p>
    </Item>
  );
};

interface MenuItemProps {
  name: any;
  selector: SectionType;
  icon: React.ElementType;
}

const LayerItem: React.FC<MenuItemProps> = ({ name, selector, icon: Icon }) => {
  const stateSelector = useGlobalStore((state) => state.selector);
  const setSelector = useGlobalStore((state) => state.setSelector);

  const isActive = selector === stateSelector;
  const onChange = () => setSelector(isActive ? null : selector);

  return (
    <Item onClick={onChange}>
      <Icon />
      <p>{name}</p>
    </Item>
  );
};

const Item = styled.div`
  //width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  //padding: 2rem 1rem;
  position: relative;
  transition:
    background 0.3s,
    color 0.3s;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    margin-top: 0.8rem;
  }

  .hover-icon,
  .hover-color,
  .hover-alt,
  .hover-fill {
    transition: all 0.3s;
  }

  img {
    z-index: 2;
    user-select: none;
  }

  background: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.text.primary};

  .hover-icon {
    fill: transparent;
  }

  .hover-fill {
    fill: ${(props) => props.theme.palette.text.primary};
  }

  .hover-color {
    fill: ${(props) => props.theme.palette.text.primary};
    stroke: ${(props) => props.theme.palette.text.primary};
  }

  .hover-alt {
    fill: ${(props) => props.theme.palette.text.primary};
    stroke: ${(props) => props.theme.palette.background.primary};
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 32px;
  padding: 0 20px;
  height: 75px;
  box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
  /* border-radius: 0px 0px 8px 8px; */
  z-index: 2001;
  background: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.text.primary};

  ${media.lg`
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 0 10px;
        border-radius: 0px;
    `}
`;
