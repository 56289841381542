import Grid from '@/ui/components/Grid';
import React from 'react';
import styled from 'styled-components';

const Toolbar = ({ children }) => {
  return (
    <SectionToolbar>
      <Grid container>{children}</Grid>
    </SectionToolbar>
  );
};

export default Toolbar;

const SearchLabel = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.4rem;
`;
const BlackLabel = styled.div`
  font-size: 10px;
  line-height: 16px;
  color: #2c2c2c;
  margin-bottom: 0.4rem;
  font-family: 'Open Sans';
  font-weight: 600;
`;
const ToolbarItem = styled(Grid)``;
ToolbarItem.defaultProps = {
  item: true,
};

Toolbar.Label = SearchLabel;
Toolbar.BlackLabel = BlackLabel;
Toolbar.Item = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
`;

const SectionToolbar = styled.div`
  /* padding: 1rem 0; */
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
