import Search, { SearchButton } from '@/components/layout/AppBar/search/Search';
import Close from '@/components/Close';
import Grid from '@/components/Grid';
import Modal from '@/components/Modal/Modal';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { NodeElement } from '@/api/project';
import Pagination from '@/ui/components/Pagination/Pagination';
import { useProjectStore } from '@/stores/projectStore';
import { useNodes } from '@/api/hooks/useNodes';
import { SelectField, SelectInput } from '@/ui/components/Field/Select';
import { Input } from '@/ui/components/Field/Input';
import { debounce } from 'lodash';
import FormLoader from '@/components/ui/form/FormLoader';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import {
  ControlsWrapper,
  FormControls,
} from '@/components/ui/form/FormControls';
import useResponsive from '@/hooks/useResponsive';
import MobilePlaceItem from './MobilePlaceItem';
import { useFormikContext } from 'formik';
import { useUserStore, RolesEnum } from '@/stores/userStore';
import { translate } from '@/i18n';
import { useIntl } from 'react-intl';

type ReportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setFieldValue: any;
};

const SelectSeatModal: React.FC<ReportModalProps> = ({ isOpen, onClose }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [place, setPlace] = useState<any>(null);
  const [parent, setParent] = useState<string>('');
  const [name, setName] = useState<string>('');
  const intl = useIntl();

  const { values, setFieldValue } = useFormikContext<any>();
  const { isMobile } = useResponsive();

  const categories = useUserStore((state) => state.categories);
  const role = useUserStore((state) => state.role);

  const nodes = useProjectStore((state) => state.nodes);
  const { data, isLoading } = useNodes({
    page: currentPage - 1,
    perPage: 20,
    name,
    parent,
    sort: 'name',
    direction: 0,
    bookable: 1,
    categories: role === RolesEnum.Admin ? undefined : categories.join(','),
  });

  const handleSelection = async () => {
    if (place) {
      setFieldValue('seat', {
        id: place.id,
        name: [place.place, place.spot].join('. '),
      });
    }
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setPlace({
        id: values.seat.id || '',
        name: values.seat.name || '',
      });
      setParent('');
      setCurrentPage(1);
      setName('');
    }
  }, [isOpen]);

  const handleNameChange = (e) => setName(e?.target?.value);
  const handleParentChange = (e) => setParent(e?.target?.value);

  const debouncedNameResponse = useMemo(() => {
    return debounce(handleNameChange, 500);
  }, []);

  useEffect(() => {
    return () => debouncedNameResponse.cancel();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={900}
    >
      <Header>
        <Title>{translate('select-location')}</Title>
        <Close
          color="#000"
          onClick={onClose}
        />
      </Header>
      <div>
        <Grid>
          <Filters>
            <FiltersItem>
              <SelectInput
                $fullWidth
                onChange={handleParentChange}
                value={parent}
              >
                <option value="">{translate('all-levels')}</option>
                {nodes.map((node) => (
                  <option
                    key={node.id}
                    value={node.id}
                  >
                    {node.name}
                  </option>
                ))}
              </SelectInput>
            </FiltersItem>
            <FiltersItem>
              <Input
                $fullWidth
                placeholder={intl.formatMessage({ id: 'search-by-name' })}
                onChange={debouncedNameResponse}
                // value={name}
              />
            </FiltersItem>
            <FiltersSearch>
              <SearchButton />
            </FiltersSearch>
          </Filters>

          {!isMobile && (
            <Grid.RowHeader $cols="20px 1fr 1fr 1fr">
              <Grid.Item />
              <Grid.Item>{translate('location')}</Grid.Item>
              <Grid.Item>{translate('place')}</Grid.Item>
              <Grid.Item>{translate('type')}</Grid.Item>
            </Grid.RowHeader>
          )}

          {data && data.items && data?.items.length ? (
            <>
              {!isMobile &&
                data.items.map((place) => (
                  <PlaceItem
                    key={place.id}
                    place={place}
                    handleSelection={setPlace}
                  />
                ))}
              {isMobile &&
                data.items.map((placeData) => (
                  <MobilePlaceItem
                    key={placeData.id}
                    place={placeData}
                    selected={place}
                    handleSelection={setPlace}
                  />
                ))}

              <PaginationWrapper>
                <Pagination
                  inverse
                  currentPage={currentPage}
                  total={data.total}
                  itemsPerPage={20}
                  handlePageChange={setCurrentPage}
                />
              </PaginationWrapper>
            </>
          ) : (
            <NotFound>{translate('no-results')}</NotFound>
          )}

          <FormLoader isLoading={isLoading} />

          <ControlsWrapper>
            <FormControls>
              <PrimaryButton
                $fullWidth
                type="submit"
                onClick={handleSelection}
              >
                {translate('select')}
              </PrimaryButton>
              <DefaultButton
                $fullWidth
                onClick={onClose}
                type="button"
              >
                {translate('cancel')}
              </DefaultButton>
            </FormControls>
          </ControlsWrapper>
        </Grid>
      </div>
    </Modal>
  );
};

export default SelectSeatModal;

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 60px;
  column-gap: 14px;
  padding: 6px 0 14px 0;
`;
const FiltersSearch = styled.div``;
const FiltersItem = styled.div``;

const PlaceItem: React.FC<{
  place: NodeElement;
  handleSelection: React.Dispatch<any>;
}> = ({ place, handleSelection }) => {
  const { nodes } = useProjectStore();
  const placename = nodes.find((tree) => tree.id == Number(place.parent));

  return (
    <Grid.Row
      $cols="20px 1fr 1fr 1fr"
      as="label"
      $label
      onClick={() => {
        handleSelection({
          place: placename?.name || 'Не указано',
          spot: place.name,
          id: place.id,
        });
      }}
    >
      <Grid.Item>
        <input
          type="radio"
          name="employee"
          value="1"
        />
      </Grid.Item>
      <Grid.Item>{placename ? placename.name : 'Не указано'}</Grid.Item>
      <Grid.Item>{place.name}</Grid.Item>
      <Grid.Item>{place.type_name}</Grid.Item>
    </Grid.Row>
  );
};

const PaginationWrapper = styled.div`
  padding: 2rem 0 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`;
