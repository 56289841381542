import { ISettings } from '@/hooks/settings/settings.interface';
import useSettings from '@/hooks/settings/use-settings';

type Selector<T> = (settings: ISettings) => T;
type InferReturnType<T> = T extends Selector<infer R> ? R : never;

// Перегрузка с фоллбеком
export function useSettingsSelector<T extends Selector<any>>(
  selector: T,
  fallback: InferReturnType<T>,
): InferReturnType<T>;

// Перегрузка без фоллбека
export function useSettingsSelector<T extends Selector<any>>(
  selector: T,
): InferReturnType<T> | undefined;

// Реализация
export function useSettingsSelector<T extends Selector<any>>(
  selector: T,
  fallback?: InferReturnType<T>,
): InferReturnType<T> | undefined {
  // const queryClient = useQueryClient()
  // const settings = queryClient.getQueryData<ISettings>('settings')
  // TODO: try to make observable getQueryData
  const { data: settings } = useSettings();

  if (typeof selector === 'function' && settings) {
    try {
      const result = selector(settings);
      if (result === undefined) {
        return fallback;
      }
      return result;
    } catch (error) {
      console.error('Invalid selector:', error);
      return fallback;
    }
  }

  return fallback;
}
