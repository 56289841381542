export enum StatusResponseEnum {
  Success = 'ok',
  Error = 'error',
  NotAuthorized = 'auth',
}

export type ApiResponseType = {
  status: StatusResponseEnum;
  controller: string;
  authorization: any;
  error_info: {
    message: string;
    place: string;
    trace: string;
  };
  ws_auth: {
    wst: string;
  };
  // project_access: string
  // project_user_data: {
  //     role: string
  //     plugin_data: {
  //         [key: string]: any
  //     }
  // },
};
