import React from 'react';
import styled, { css } from 'styled-components';

import { Formik, Form, Field, FieldProps } from 'formik';

const ErrorMessage = styled.div`
  line-height: 24px;
  color: rgb(251, 105, 94);
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 5px;
  border-radius: 3px;
  transition: background-color 0.15s ease-in-out 0s;
`;

export const SelectInput = styled.select<any>`
  padding: 0 1rem;
  outline: none;
  border: none;
  border: 1px solid ${(props) => props.theme.palette.text.label};
  display: block;
  border-radius: 8px;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  line-height: 16px;
  color: #838d96;
  -webkit-appearance: none;

  ${({ $error }) =>
    $error &&
    css`
      border-color: rgb(251, 105, 94);
    `}

  ${({ $transparent }) =>
    $transparent
      ? css`
          background: transparent;
          color: ${(props) => props.theme.palette.text.label};
          border: 1px solid ${(props) => props.theme.palette.text.label};

          &::placeholder {
            color: #e3e3e3;
          }
        `
      : css`
          background: #fff;
          color: ${(props) => props.theme.palette.text.secondary};
          border: 1px solid ${(props) => props.theme.palette.background.primary};
        `};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string;
  options: Array<{ value: string; label: string }>;
}) => {
  const { options } = props;

  return (
    <InputWrapper>
      <SelectInput
        {...field}
        {...props}
        // value={(options ? options.find(option => option.value === field.value) : '') as any}
      >
        {options.map((option) => (
          <option
            key={'select ' + option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </SelectInput>
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage>{errors[field.name]}</ErrorMessage>
      )}
    </InputWrapper>
  );
};
