import LayerIcon from '@/components/shared/map/layer-selector/LayerIcon';
import LayerSelectorModal from '@/components/shared/map/layer-selector/LayerSelectorModal';
import ModalService from '@/components/shared/modal/ModalService';
import { translate } from '@/i18n';
import { useMapStore } from '@/stores/mapStore';
import media from '@/ui/media';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

const LayerSelector = () => {
  const option = useMapStore((state) => state.option);
  const setOption = useMapStore((state) => state.setOption);

  const handleLayerSelector = () => {
    ModalService.open(LayerSelectorModal, { option, setOption });
  };

  return (
    <LayerSelectorWrapper>
      <AnimatePresence>
        {option === 'load' && (
          <LayersLoad
            key="box"
            initial={{ y: '50%', opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: '50%', opacity: 0, transition: { duration: 0.1 } }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            <Caption>{translate('layer-selector.place-load')}</Caption>
            <Loaded>{translate('layer-selector.high-load')}</Loaded>
            <Moderate>{translate('layer-selector.severe-load')}</Moderate>
            <Light>{translate('layer-selector.low-load')}</Light>
            <NotBookable>
              {translate('layer-selector.non-bookable')}
            </NotBookable>
          </LayersLoad>
        )}
      </AnimatePresence>

      <LayerSelectorButton onClick={handleLayerSelector}>
        <LayerIcon />
      </LayerSelectorButton>
    </LayerSelectorWrapper>
  );
};

const Typography = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  text-align: right;
  color: #2c2c2c;
  padding: 0 8px;

  &:not(:last-child) {
    margin-bottom: 1px;
  }
`;

const Caption = styled(Typography)``;

const Loaded = styled(Typography)`
  background: #ae0000;
  color: #fff;
`;

const Moderate = styled(Typography)`
  background: #f3bb5e;
`;

const Light = styled(Typography)`
  background: #079dac;
  color: #fff;
`;

const NotBookable = styled(Typography)`
  background: #d9d9d9;
`;

const LayersLoad = styled(motion.div)`
  background: #fff;
  border: 1px solid #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;

  min-width: 160px;
`;

const LayerSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-height: 420px) {
    flex-direction: row;
  }
`;

const LayerSelectorButton = styled.div`
  cursor: pointer;
  background: #079dac;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${media.md`
    bottom: 10rem;
  `}
`;

export default LayerSelector;
