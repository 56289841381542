import { api, ApiResponseType } from '@/api';
import axios from 'axios';

export const MailService = {
  async createMaintenance(createMaintenanceDto: any) {
    const data = new FormData();
    data.append('subject', String(createMaintenanceDto.subject));
    data.append('message', String(createMaintenanceDto.message));
    data.append('recipient', String(createMaintenanceDto.recipient));

    return axios.post<any>(`/mailto/email.php`, data);
  },
  async getReport({ workspaceId, projectId, reportId, params }: GetReportDTO) {
    const data = new FormData();
    // if (day || moment === 'current') {
    // 	const num = getDay(date)
    // 	const weekDay = num == 0 ? 6 : num - 1
    // 	const rangeStart = num == 0 ? 330 : weekDay * 48
    // 	const rangeEnd = (weekDay + 1) * 48 - 1

    // 	const range = weekDay == 0 ? `330,331,332,333,334,335,${[...Array(rangeEnd + 1).keys()].join(',')}` : `RANGE:${rangeStart-6}:${rangeEnd-6}`

    // 	data.append('filters[matchgap]', String(range))
    // }

    data.append('report_id', String(reportId));
    data.append('params', JSON.stringify(params));
    data.append('withExtensions', JSON.stringify(true));

    return api.post<GetReportResponse>(
      `/Meta/Report/API?workspace_id=${workspaceId}&project_id=${projectId}&action=run_report`,
      data,
    );
  },
};

type GetReportDTO = {
  workspaceId: number;
  projectId: number;
  reportId: string;
  params: any;
};

export interface GetReportsResponse extends ApiResponseType {
  reports: IReport[];
}

export interface GetReportResponse extends ApiResponseType {
  report: any[];
}

export interface Columns {
  id: string;
  name: string;
  parent: string;
  type_uid: string;
  stack: string;
  category: string;
  type_name: string;
}

export interface Parameters {
  start: string[];
  end: string[];
  bookable_only: string[];
  type_uid: string[];
  types: string[];
  root_layer: string[];
}

export interface IReport {
  access_level: string;
  report_id: string;
  name: string;
  plugin_id: string;
  info: string;
  columns: Columns;
  parameters: Parameters;
}
