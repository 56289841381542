export class BomjMapper {
  public static getColumns(report = []) {
    if (!report.length) {
      return [];
    }
    return Object.keys(report[0]).filter(
      (v) => !['id', 'enabled', 'login', 'role', 'upd'].includes(v),
    );
  }

  public static getTranslations(columns, userProps) {
    console.log(userProps);
    const translations = {};

    userProps.forEach((prop) => {
      if (columns.includes(prop.key)) {
        translations[prop.key] = prop.label;
      }
    });

    return translations;
  }
}
