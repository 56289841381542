import { useGlobalStore } from '@/stores/globalStore';
import { useMapStore } from '@/stores/mapStore';
import { useEffect } from 'react';
import useSettings from '@/hooks/settings/use-settings';

const MapController = () => {
  const polygon = useMapStore((state) => state.polygon);
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const mapLayer = useMapStore((state) => state.mapLayer);
  const setPolygon = useMapStore((state) => state.setPolygon);
  const updateDepartments = useMapStore((state) => state.updateDepartments);

  const { data } = useSettings();

  useEffect(() => {
    if (data && data.departments) {
      updateDepartments(data.departments);
    }
  }, [data?.departments]);

  const onLayerChange = (event) => {
    const {
      detail: { layerId },
    } = event;
    setPolygon(layerId);
  };

  useEffect(() => {
    document.addEventListener('layer-change', onLayerChange, true);

    return () =>
      document.removeEventListener('layer-change', onLayerChange, true);
  }, []);

  useEffect(() => {
    const current = polygon == activeLayer;
    if (!current) {
      setPolygon(null);
    }
  }, [activeLayer, polygon]);

  return null;
};

export default MapController;
