import { LOCALES } from './../locales';

export default {
  [LOCALES.TURKISH]: {
    'log-in': 'Giriş Yap',
    'log-out': 'Çıkış Yap',

    search: 'Ara',
    loading: 'Yükleniyor',
    load: 'yük',
    'no-results': 'Hiçbir şey bulunamadı',
    'select-period': 'Dönem Seç',
    profile: 'Profil',

    'start-time': 'Başlangıç Saati',
    'end-time': 'Bitiş Saati',
    'start-date': 'Başlangıç Tarihi',
    'end-date': 'Bitiş Tarihi',
    'brand-name': 'Ofis Kartı',
    bookings: 'Rezervasyon',
    employees: 'Çalışanlar',
    objects: 'Nesneler',
    reports: 'Raporlar',
    levels: 'Seviyeler',
    info: 'Bilgi',
    close: 'Kapat',
    delete: 'Sil',
    cancel: 'İptal',
    confirm: 'Onayla',
    'internal-add-error': 'Ekleme sırasında bir hata oluştu',

    place: 'Yer',
    booking: 'Rezervasyon',
    type: 'Tür',
    position: 'Konum',
    description: 'Açıklama',
    'all-bookings': 'Tüm rezervasyonlar',
    'past-bookings': 'Geçmiş',
    'current-bookings': 'Mevcut',
    'future-bookings': 'Gelecek',
    'constant-bookings': 'Sürekli',
    'weekly-bookings': 'Haftalık',
    'full-name': 'Tam Adı',
    location: 'Yer',
    date: 'Tarih',
    common: 'Ortak',
    recurrent: 'Tekrarlayan',
    constant: 'Sürekli',
    success: 'Başarılı',
    error: 'Hata',
    'booking-deleted': 'Rezervasyon silindi!',
    'failed-to-delete-booking': 'Rezervasyon silinemedi',
    'delete-booking': 'Rezervasyonu sil?',
    'delete-booking-confirm': 'Rezervasyonu silmek istediğinizden emin misiniz?',
    'print-layer': 'Yazdır',
    'printing-layer': 'Yazdırılıyor...',

    'auth_auth-text': 'Yetkilendirme',
    auth_login: 'Giriş',
    auth_password: 'Şifre',
    'appbar_datepicker-title': 'Haritada rezervasyonları göster',
    'search-bookings-by-user-or-object': 'Çalışan veya yer ara',
    'object-type': 'Yer türü',
    'date-display-error': 'Tarih görüntüleme hatası',
    'search-by-name': 'İsme göre ara',
    'all-levels': 'Tüm seviyeler',
    all: 'Tümü',
    service: 'Servis',
    'about-object': 'Nesne hakkında',
    'object-types': 'Tür',
    'object-name': 'Ad',
    'show-on-map': 'Haritada göster',
    more: 'Daha fazla',
    'search-by-userdata': 'Çalışan bilgisine göre ara',
    booking_category: 'Rezervasyon Kategorileri',
    'about-employee': 'Çalışan hakkında',
    'not-authorized': 'Yetkili değil',
    'booking-added': 'Rezervasyon eklendi',
    'booking-added-confirm': 'Rezervasyonunuz başarıyla eklendi',
    'allowed-for-authorized': 'Sadece yetkili kullanıcılar için izinli',
    'booking-type': 'Rezervasyon türü',
    'select-location': 'Yer seçin',
    'choose-location': 'Yer seçin',
    'select-object': 'Nesne seçin',
    'choose-employee': 'Çalışan seçin',
    'select-employee': 'Çalışan seçin',
    'start-datetime': 'Başlangıç Tarih ve Saati',
    'end-datetime': 'Bitiş Tarih ve Saati',
    'already-booked': 'Başka bir çalışan tarafından rezerve edildi',
    'booked-by-me': 'Diğer rezervasyonum',
    'my-parallel-bookings': 'Paralel rezervasyonlarım',
    'current-booking': 'Mevcut rezervasyon',
    free: 'Boş',
    show: 'Göster',
    hide: 'Gizle',
    'no-parallel-bookings': 'Paralel rezervasyon yok',
    'bookings-for-period': 'Dönem için rezervasyonlar',
    'show-bookings-between': '{start} - {end}',
    'calendar-initialize-error': 'Takvim başlatılamadı',
    select: 'Seç',
    'add-interval': 'Aralık ekle',
    'week-days': 'Hafta günü',
    monday: 'Pzt',
    tuesday: 'Salı',
    wensday: 'Çrş',
    thursday: 'Per',
    friday: 'Cum',
    saturday: 'Cmt',
    sunday: 'Paz',
    'start-working': 'Çalışma Başlangıcı',
    'end-working': 'Çalışma Bitişi',
    save: 'Kaydet',
    submit: 'Ekle',
    resolver_state_1: 'Mevcut rezervasyon başka bir rezervasyonla çakışıyor',
    resolver_state_2: 'Mevcut rezervasyon başka bir kişinin rezervasyonuyla çakışıyor',
    resolver_state_4: 'Bu nesne için rezervasyon oluşturulamıyor',
    resolver_state_8: 'Bu tür nesneler için yeni rezervasyon oluşturamazsınız',
    resolver_state_10: 'Rezervasyon nesnesi bulunamadı',
    'bookings-report': 'Seçilen gün için rezervasyon listesi',
    'place-report': 'Çalışma yeri yükü',
    'square-report': 'Alan raporu',
    'report-template': 'Rapor: "{name}"',
    'free-places-v1': 'Dönem için boş yerler',
    'bomj-users': 'Yersiz çalışanlar',
    'non-free-places-v1': 'Dönem için dolu yerler',
    'no-place-users': 'Yersiz çalışanlar',

    'report-full-name': 'Tam Adı',
    'report-object': 'Yer',
    'report-booking-type': 'Tür',
    'report-date': 'Tarih',
    'report-place-name': 'Yer adı',
    'report-laod': 'yük',

    'report-type_name': 'Tür',
    'report-name': 'Ad',
    'report-parent': 'Seviye',

    'not-specified': 'Belirtilmedi',

    'add-report': 'Rapor oluştur',
    'create-report': 'Rapor oluştur',
    'select-report': 'Rapor türünü seçin',
    'select-colummns': 'Gösterilecek verileri seçin',
    user: 'Kullanıcı',
    'save-pdf': 'PDF olarak kaydet',
    'save-csv': 'CSV olarak kaydet',
    'select-data-to-display': 'Gösterilecek verileri seçin',
    'place-name': 'Yer adı',
    name: 'Ad',

    'create-report-bookings': 'Rezervasyon raporu',
    'create-report-employees': 'Çalışan raporu',
    'create-report-objects': 'Nesne raporu',
    object: 'Nesne',

    book: 'Rezerve Et',
    'project-not-found': 'Proje bulunamadı',
    'go-to-test-project': 'Test projesine git',
    employee: 'Çalışan',
    'object-service': 'Nesne servisi',
    'service-type': 'Servis türü',
    cleaning: 'Temizlik',
    maintenance: 'Bakım',
    'more-info': 'Daha fazla bilgi',
    describe: 'Daha fazla açıklayın',
    send: 'Gönder',
    'service-submitted': 'Başvuru işleme alındı',

    'error-load-metadata': 'Proje meta verileri yüklenemedi',
    edit: 'Düzenle',
    'map-loading': 'Harita yükleniyor',
    'data-loading': 'Veri yükleniyor',
    'my-bookings': 'Rezervasyonlarım',
    'search-bookings-by-user': 'Çalışana göre ara',
    'search-bookings-by-object': 'Yere göre ara',
    'report-area-cabinet-name': 'Ofis adı',
    'report-area-parent-name': 'Ana adı',
    'report-area-arender-name': 'Kiracı adı',
    'report-area-square': 'alan, m²',
    'report-area-places': 'Ofisteki yer sayısı',

    'layer-selector.place-load': 'Yer yükü',
    'layer-selector.high-load': 'Yüksek (> %70)',
    'layer-selector.severe-load': 'Orta (%30 - %70)',
    'layer-selector.low-load': 'Düşük (< %30)',
    'layer-selector.non-bookable': 'Rezerve edilemeyen',
    'layer-selector.apply': 'Uygula',
    'layer-selector.load-per-place': 'Çalışma yeri yükü',
    'layer-selector.basic': 'Temel',
    'layer-selector.employees': 'Çalışanlar',
    'layer-selector.bookable-places': 'Rezerve edilebilir',
    'layer-selector.without-department': 'Departmansız',
    'layer-selector.department': 'Departmanlar',
    'layer-selector.layers': 'Katmanlar',
    'layer-selector.layers-title': 'Seviye işaretleme',

    'appbar.office-plan': 'Ofis Planı',
    'appbar.back': 'Geri Dön',
    'show-booking-grid': "Yer yükünü göster",
    'hide-booking-grid': "Yer yükünü gizle",
  }
}
