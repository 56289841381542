import { useLayerView } from '@/api/hooks/useLayerView';
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector';
import { useGlobalStore } from '@/stores/globalStore';
import { useProjectStore } from '@/stores/projectStore';
import { SelectInput } from '@/ui/components/Field/Select';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigationStore } from './navigation.store';

export const RouteSelector = () => {
  const route = useNavigationStore((state) => state.route);
  const setFrom = useNavigationStore((state) => state.setFrom);
  const setTo = useNavigationStore((state) => state.setTo);
  const clearRoute = useNavigationStore((state) => state.clearRoute);
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const nodes = useProjectStore((state) => state.nodes);
  const navigation = useSettingsSelector((settings) => settings.navigation, {
    keypointsVisible: false,
    keypoint: [],
  });

  const node = nodes.find((n) => n.id == activeLayer);
  const hasOwnView = node?.ownView;

  const layer = hasOwnView ? node.id : node?.parent || activeLayer;

  const { layerView } = useLayerView(Number(layer));
  const options = useMemo(() => {
    return layerView?.points
      .map((p) => ({ ...p, value: { x: p.x, y: p.y } }))
      .filter((p) => {
        const navigaiontTypes = navigation.keypoint.map((k) => k.name);
        return !navigaiontTypes.includes(p.type_name);
      });
  }, [layerView?.points]);

  const handleSetFrom = (event) => {
    const value = event.target.value;

    const currentNode = options?.find((opt) => opt.id == value);
    setFrom(currentNode);
  };

  const handleSetTo = (event) => {
    const value = event.target.value;

    const currentNode = options?.find((opt) => opt.id == value);
    setTo(currentNode);
  };

  useEffect(() => {
    clearRoute();
  }, [layer]);

  return (
    <Wrapper>
      <Title>Навигатор</Title>
      <TextDescription>
        Для построения маршрута, выберите точки начала и конца маршрута
      </TextDescription>
      <Form>
        <div>
          <InputLabel>Начало маршрута:</InputLabel>
          <div>
            <SelectInput
              $fullWidth
              placeholder="Выберите тип места"
              value={route.from?.id}
              onChange={handleSetFrom}
            >
              <option value="">Не выбрано</option>
              {options?.map((node) => (
                <option
                  key={node.id}
                  value={node.id}
                >
                  {node.name}
                </option>
              ))}
            </SelectInput>
          </div>
        </div>

        <div>
          <InputLabel>Конец маршрута:</InputLabel>
          <div>
            <SelectInput
              $fullWidth
              placeholder="Выберите тип места"
              value={route.to?.id}
              onChange={handleSetTo}
            >
              <option value="">Не выбрано</option>
              {options?.map((node) => (
                <option
                  key={node.id}
                  value={node.id}
                >
                  {node.name}
                </option>
              ))}
            </SelectInput>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  margin-right: 26px;
  box-shadow: 4px 8px 8px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 20px;
  min-width: 220px;
  max-width: 500px;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
`;

const TextDescription = styled.div`
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #2c2c2c;
  margin-bottom: 8px;
`;
