import React from 'react';

const Chevron = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="1"
        width="1.41421"
        height="8.48527"
        transform="rotate(-45 0 1)"
      />
      <rect
        x="11"
        width="1.41421"
        height="8.48527"
        transform="rotate(45 11 0)"
      />
    </svg>
  );
};

export default Chevron;
