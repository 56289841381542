import { Layer } from 'react-konva';
import { memo, useEffect, useMemo } from 'react';
import Polygon from './Polygon';
import { useMapStore } from '@/stores/mapStore';

const dispatchPolygonsLoaded = () => {
  const event = new CustomEvent('polygons-loaded');
  document.dispatchEvent(event);
};

const PolygonsLayer: React.FC<any> = ({ polygons = [] }) => {
  const layers = useMapStore((state) => state.layers);

  const isVisible = useMemo(() => layers['layers'], [layers]);
  const [width, height] = useMapStore((state) => state.size);

  useEffect(() => {
    dispatchPolygonsLoaded();
  }, [polygons]);

  if (!isVisible) return null;

  return (
    <Layer>
      {polygons.map(({ id, polygon }) => (
        <Polygon
          id={id}
          key={id}
          width={width}
          height={height}
          polygon={polygon}
        />
      ))}
    </Layer>
  );
};

export default memo(PolygonsLayer);

// PolygonsLayer.whyDidYouRender = true;
