import React from 'react';
import styled from 'styled-components';

export const ButtonBase = styled.button`
  background: ${(props) => props.theme.palette.default.main};
  color: ${(props) => props.theme.palette.default.text};
  border-radius: 5px;
  padding: 12px 15px;
  min-width: 140px;
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 16px;

  cursor: pointer;
`;
