import React, { useState } from 'react'
import Grid from '@/ui/components/Grid'
import BookingGridSelector from './BookingGridSelector'
import { ErrorBoundary } from 'react-error-boundary'
import { translate } from '@/i18n'
import styled from 'styled-components'
import ToggleBookingGrid from './ui/ToggleBookingGrid'

const BookingGridField = ({ bookings, addItem }) => {
	const [isShow, setIsShow] = useState(false)

	const handleOnClick = () => {
		setIsShow(!isShow)
	}

	return (
		<ErrorBoundary
			fallback={<div>{translate('calendar-initialize-error')}</div>}
		>
			<Grid container>
				<Grid item xs={12} md={12}>
					<ToggleBookingGridWrap>
							<ToggleBookingGrid
									label={isShow ? translate('hide-booking-grid') : translate('show-booking-grid')}
									onClick={handleOnClick}
									isActive={isShow}
							/>
					</ToggleBookingGridWrap>
					{isShow ? (
						<BookingGridSelector bookings={bookings} addItem={addItem} />
					) : null}
				</Grid>
			</Grid>
		</ErrorBoundary>
	)
}

export default BookingGridField

const ToggleBookingGridWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`