import { useProjectStore } from '@/stores/projectStore';
import React from 'react';
import DateSelector from '@/components/shared/map/map-controls/DateSelector';
import { useMapStore } from '@/stores/mapStore';
import useResponsive from '@/hooks/useResponsive';

const MapDateSelector = () => {
  const fullMode = useProjectStore((state) => state.fullMode);
  const mapDateIsVisible = useMapStore((state) => state.mapDateIsVisible);
  const { isMobile } = useResponsive();

  if (!fullMode || (isMobile && !mapDateIsVisible)) return null;

  return <DateSelector />;
};

export default MapDateSelector;
