import { translate } from '@/i18n';
import { InputField } from '@/ui/components/Field/Input';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import Grid from '@/ui/components/Grid';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { ModalSelectInputField } from '../shared/ModalSelectInputField';
import SelectSeatModal from './SelectSeatModal';
import Chevron from '@/components/icons/Chevron';
import styled from 'styled-components';

export const SelectSeatField = () => {
  const [isOpen, setOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const intl = useIntl();

    return (
        <>
            <Grid item xs={12} md={4} onClick={() => setOpen(true)}>
                <FormLabel>{translate('choose-location')}</FormLabel>
                <FieldWrap>
                    <Field
                        name="seat"
                        $fullWidth
                        type="text"
                        placeholder={intl.formatMessage({ id: 'location' })}
                        disabled
                        component={ModalSelectInputField}
                        style={{ pointerEvents: 'none'}}
                    />
                    <Chevron />
                </FieldWrap>
            </Grid>
            <Portal>
                <SelectSeatModal isOpen={isOpen} setFieldValue={setFieldValue} onClose={() => setOpen(false)} />
            </Portal>
        </>
    )
}

export const SelectPlace = () => {
  const [isOpen, setOpen] = useState(false);
  const { setFieldValue } = useFormikContext();

    return (
        <>
            <Grid item xs={12} md={4} onClick={() => setOpen(true)}>
                <FormLabel>Выберите место</FormLabel>
                <Field name="spot" $fullWidth type="text" placeholder="место" disabled component={InputField} />
            </Grid>
            <Portal>
                <SelectSeatModal isOpen={isOpen} setFieldValue={setFieldValue} onClose={() => setOpen(false)} />
            </Portal>
        </>
    )
}

const FieldWrap = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: 22px;
		transform: translateY(-50%);
		right: 10px;
		width: 18px;
		height: 18px;
		color: #828282;
		pointer-events: none;
	}
`