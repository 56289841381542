import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { UserService } from '../services/user.service';

export const useUser = (id: number | null) => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();

  const { data, isLoading, refetch } = useQuery(
    ['user', workspaceId, projectId, id],
    () => UserService.getUser({ workspaceId, projectId, userId: Number(id) }),
    {
      enabled: !!workspaceId && !!projectId && !!id,
      select: ({ data }) => ({ user: data.user }),
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить информацию о пользователе',
          },
          { variant: 'error' },
        );
      },
    },
  );

  return { data, isLoading, refetch };
};
