import { parseISO, startOfWeek, format, parse } from 'date-fns';
import { bookingInterval } from '../constants/booking.constants';

export const extractGaps = (gap) => {
  let start = gap[0];
  let temp = gap[0];
  let slots: any[] = [];

  gap.forEach((val, idx) => {
    const next = gap[idx + 1];
    if (!next && start !== null) {
      slots.push([start, val]);
    } else {
      if (start === null) {
        start = val;
      }
      const diff = next - val;
      if (diff > 1) {
        slots.push([start, val]);
        start = null;
      }
    }
    temp = val;
  });

  return slots;
};

export const convertGapToSlotWeekly = (gap: [number, number], week) => {
  const weekStart = startOfWeek(new Date(week), { weekStartsOn: 1 });
  // 2022-04-11T00:00:00

  return {
    start: format(
      new Date(weekStart.getTime() + 30 * 60 * 1000 * gap[0]),
      "yyyy-MM-dd'T'HH:mm:ss",
    ),
    end: format(
      new Date(
        weekStart.getTime() +
          bookingInterval * Number(gap[1]) +
          bookingInterval,
      ),
      "yyyy-MM-dd'T'HH:mm:ss",
    ),
  };
};

export const convertGapToSlot = (gap: [number, number]) => {
  const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  // 2022-04-11T00:00:00

  return {
    start: format(
      new Date(weekStart.getTime() + 30 * 60 * 1000 * gap[0]),
      "yyyy-MM-dd'T'HH:mm:ss",
    ),
    end: format(
      new Date(
        weekStart.getTime() +
          bookingInterval * Number(gap[1]) +
          bookingInterval,
      ),
      "yyyy-MM-dd'T'HH:mm:ss",
    ),
  };
};

export const generateBookingGridDates = (data) => {
  if (!data || !data.booking) return [];
  const { booking } = data;
  let dates: any = [];

  if (booking.booking_type == 3) {
    return [];
  }

  if (booking.booking_type !== 2) {
    dates.push({
      start: formatToISOTimezone(booking.start),
      end: formatToISOTimezone(booking.end),
    });
  }

  if (booking.childs && booking.childs.length) {
    const temp = booking.childs.map((item) => ({
      start: formatToISOTimezone(item[0]),
      end: formatToISOTimezone(item[1]),
    }));
    dates = dates.concat(temp);
  }

  return dates;
};

export const dateToMilliseconds = (date: string | Date): number => {
  return new Date(date).getTime();
};

export const isDateInRange = (date, range) => {
  return (
    dateToMilliseconds(date.start) >= dateToMilliseconds(range.min) &&
    dateToMilliseconds(date.end) <= dateToMilliseconds(range.max)
  );
};

export const formatToISOTimezone = (date: string) => parseISO(date + '.000Z');

export const formatDate = (date: string | Date) => {
  if (typeof date === 'string')
    return format(new Date(date), 'dd.MM.yyyy HH:mm');
  return format(date, 'dd.MM.yyyy HH:mm');
};

export const formatToTimezone = (date: string) => {
  return format(parseISO(date + '.000Z'), 'dd.MM.yyyy HH:mm');
};

export const formatToTimezoneDate = (date: string) => {
  return format(parseISO(date + '.000Z'), 'dd.MM.yyyy HH:mm');
};

export const generateGap = ([start, end]: number[]) => {
  const startId = Number(start);
  const endId = Number(end);

  const gap: number[] = [];

  for (let i = startId; i <= endId; i++) {
    gap.push(i);
  }

  return gap;
};

export const formatLocalDateToAPI = (date: Date) => {
  const local = date.toISOString().replace('Z', '');
  return new Date(local);
};
export const formatToReport = (date) =>
  format(new Date(date), 'yyyy-MM-dd-HH:mm:ss');
export const formatToAPI = (date) =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");
export const formatToDateAPI = (date) => format(new Date(date), 'yyyy-MM-dd');
