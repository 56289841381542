import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import { Portal } from 'react-portal';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { animated, useTransition } from '@react-spring/web';
import { easePoly } from 'd3-ease';
import { addMinutes, startOfDay, endOfDay } from 'date-fns';
import useResponsive from '@/hooks/useResponsive';
import { dateFnsLocale } from '@/App';
import { translate } from '@/i18n';
import { useFloating } from '@floating-ui/react-dom';

const SingleDatePicker = ({ selection, open, setSelection, setOpen }) => {
  const { isPhone } = useResponsive();

  let { refs, floatingStyles } = useFloating();

  const ref = useRef<any>();

  const handleSelect = (date) => {
    setSelection({
      startDate: startOfDay(date),
      endDate: endOfDay(date),
      key: 'selection',
    });
  };

  const transitions = useTransition(open, {
    from: { opacity: 0, scale: 0.8 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.8 },
    reverse: open,
    config: {
      duration: 300,
      easing: easePoly.exponent(2),
    },
  });

  useOnClickOutside(
    ref,
    useCallback(() => setOpen(false), []),
  );

  return (
    <ShowContainer ref={refs.setReference}>
      {transitions(
        (style, item) =>
          item && (
            <Portal>
              <ShowPicker
                ref={refs.setFloating}
                style={{ ...floatingStyles, ...style }}
              >
                <Wrapper ref={ref}>
                  <Calendar
                    onChange={handleSelect}
                    date={selection.startDate}
                    locale={dateFnsLocale}
                    showDateDisplay={false}
                    direction={isPhone ? 'vertical' : 'horizontal'}
                  />
                  <AcceptButton onClick={() => setOpen(!open)}>
                    {translate('select-period')}
                  </AcceptButton>
                </Wrapper>
              </ShowPicker>
            </Portal>
          ),
      )}
    </ShowContainer>
  );
};

export default SingleDatePicker;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
`;

const AcceptButton = styled.button`
  border: none;
  outline: none;
  background-color: #079dac;
  color: #fff;
  padding: 15px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #098f9c;
  }
`;

const ShowPicker = styled(animated.div)`
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  z-index: 12001;
  max-width: 100%;
  max-height: calc(100% - 75px);
  overflow: auto;
`;

const ShowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
