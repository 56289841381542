import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { Portal } from 'react-portal';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { animated, useTransition } from '@react-spring/web';
import { easePoly } from 'd3-ease';
import { addMinutes, format, setHours, setMinutes } from 'date-fns';
import { useGlobalStore } from '@/stores/globalStore';
import { media } from '@/ui/media';
import useResponsive from '@/hooks/useResponsive';
import { SelectInput } from '@/ui/components/Field/Select';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import translate from '../../../../i18n/translate';
import { dateFnsLocale } from '@/App';
import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import Chevron from '@/components/icons/Chevron';

const times = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48,
];

const convertToTimePeriod = (gap) => {
  const totalMinutes = gap * 30;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes - hours * 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

const formatToGapTime = (date, gap, end = false) => {
  const totalMinutes = gap * 30;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return setMinutes(setHours(date, hours), end ? minutes - 1 : minutes);
};

const DateSelector: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(48);
  const { isPhone } = useResponsive();

  let { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    middleware: [
      flip({ fallbackPlacements: ['top-start'] }),
      offset(8),
      shift({ padding: 8 }),
    ],
  });

  const selection = useGlobalStore((state) => state.selection);
  const setSelection = useGlobalStore((state) => state.setSelection);
  const setSeat = useGlobalStore((state) => state.setSeat);
  const setEmployee = useGlobalStore((state) => state.setEmployee);

  const ref = useRef<any>();

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    setSelection(
      formatToGapTime(startDate, startTime),
      formatToGapTime(endDate, endTime, true),
    );
    setSeat(null);
    setEmployee(null);
  };

  const transitions = useTransition(open, {
    from: { opacity: 0, scale: 0.8 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.8 },
    reverse: open,
    config: {
      duration: 300,
      easing: easePoly.exponent(2),
    },
  });

  useEffect(() => {
    let validEndTime = endTime;

    if (startTime > endTime) {
      validEndTime = startTime + 1;
      setEndTime(validEndTime);

      return;
    }

    setSelection(
      formatToGapTime(selection.startDate, startTime),
      formatToGapTime(selection.endDate, validEndTime, true),
    );
  }, [startTime, endTime]);

  useOnClickOutside(
    ref,
    useCallback(() => setOpen(false), []),
  );

  return (
    <ShowContainer ref={refs.setReference}>
      <ShowLabel>{translate('appbar_datepicker-title')}</ShowLabel>
      <SelectedDate onClick={() => setOpen(!open)}>
        <p>
          {format(selection.startDate, 'dd.MM.yyyy HH:mm')} -{' '}
          {format(addMinutes(selection.endDate, 1), 'dd.MM.yyyy HH:mm')}
        </p>
        <Chevron />
      </SelectedDate>
      {transitions(
        (style, item) =>
          item && (
            <Portal>
              <ShowPicker
                ref={refs.setFloating}
                style={{ ...floatingStyles, ...style }}
              >
                <Wrapper ref={ref}>
                  <DateRange
                    ranges={[selection]}
                    onChange={handleSelect}
                    locale={dateFnsLocale}
                    months={2}
                    showDateDisplay={false}
                    direction={isPhone ? 'vertical' : 'horizontal'}
                  />
                  <TimeWrapper>
                    <StartDate>
                      <FormLabel>{translate('start-time')}</FormLabel>
                      <SelectInput
                        $fullWidth
                        value={startTime}
                        onChange={(event) => {
                          const value = event.target.value;
                          setStartTime(Number(value));
                        }}
                      >
                        {times
                          .filter((v) => v != 48)
                          .map((_, idx) => (
                            <option
                              value={idx}
                              key={idx}
                            >
                              {convertToTimePeriod(idx)}
                            </option>
                          ))}
                      </SelectInput>
                    </StartDate>
                    <StartEnd>
                      <FormLabel>{translate('end-time')}</FormLabel>
                      <SelectInput
                        $fullWidth
                        value={endTime}
                        onChange={(event) => {
                          const value = event.target.value;
                          setEndTime(Number(value));
                        }}
                      >
                        {times.map((_, idx) => (
                          <option
                            key={idx}
                            value={idx}
                          >
                            {convertToTimePeriod(idx)}
                          </option>
                        ))}
                      </SelectInput>
                    </StartEnd>
                  </TimeWrapper>
                  <AcceptButton onClick={() => setOpen(!open)}>
                    {translate('select-period')}
                  </AcceptButton>
                </Wrapper>
              </ShowPicker>
            </Portal>
          ),
      )}
    </ShowContainer>
  );
};

export default DateSelector;

const StartDate = styled.div`
  padding-right: 15px;

  ${media.sm`
        padding-right: 0;
    `}
`;

const StartEnd = styled.div`
  padding-left: 15px;

  ${media.sm`
        padding-left: 0;
        margin-top: 16px;
    `}
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;

  div {
    flex-basis: 50%;

    ${media.sm`
            flex-basis: 100%;
        `}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  /* align-items: flex-end; */
`;

const AcceptButton = styled.button`
  border: none;
  outline: none;
  background-color: #079dac;
  color: #fff;
  padding: 15px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #098f9c;
  }
`;

const ShowPicker = styled(animated.div)`
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  z-index: 1001;
  max-width: 100%;
  max-height: calc(100% - 200px);
  overflow: auto;
`;

const ShowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background: rgb(7, 157, 172);

  ${media.lg`
		z-index: 1;
		align-items: center;
		max-width: 360px;
		width: calc(100% - 24px);
		left: 50%;
		transform: translateX(-50%);
		right: auto;
	`}
`;

const ShowLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: #ffffff;
  text-align: left;
`;

const SelectedDate = styled.div`
  font-weight: 700;
  font-family: Roboto;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    padding-right: 8px;
    text-align: left;
  }

  ${media.lg`
        font-size: 14px;
        line-height: 16px;
    `}
`;
