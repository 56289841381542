export const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(7|8|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? '+7 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], ' ', match[4]].join('');
  }

  return str;
};
