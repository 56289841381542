import { useNodes } from '@/api/hooks/useNodes';
import Close from '@/components/Close';
import { Place } from '@/components/Elements/ElementsListItem';
import Grid from '@/components/Grid';
import Modal from '@/components/Modal/Modal';
import Pagination from '@/ui/components/Pagination/Pagination';
import media from '@/ui/media';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReportButton } from './ReportView';
import Toolbar from '@/components/Toolbar/Toolbar';
import { addMinutes, endOfWeek, format, startOfWeek } from 'date-fns';
import SingleDatePicker from './pickers/SingleDatePicker';
import { Input } from '@/ui/components/Field/Input';
import { translate } from '@/i18n';
import { useIntl } from 'react-intl';

type ReportModalProps = {
  report: string | null;
  item: {
    name: string;
    key: string;
  };
  onClose: () => void;
};

const ReportModal: React.FC<ReportModalProps> = ({ report, item, onClose }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, isLoading } = useNodes({ page: currentPage - 1 });
  const [toggle, setOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<{
    startDate: any;
    endDate: any;
    key: string;
  }>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(addMinutes(new Date(), -30)),
    key: 'selection',
  });
  const intl = useIntl();
  const dataItems =
    useMemo(
      () =>
        data?.items.map((item) => ({
          ...item,
          busy: randomIntFromInterval(0, 25),
        })),
      [data?.items, selection],
    ) || [];

  return (
    <Modal
      isOpen={!!report}
      onClose={onClose}
    >
      <Header>
        <Title>
          {translate('report-template', {
            name: intl.formatMessage({ id: item.key }),
          })}
          "
        </Title>
        <ExportWrapper>
          {/* <ReportButton>PDF</ReportButton>
                    <ReportButton>CSV</ReportButton> */}
          <Close
            color="#000"
            onClick={onClose}
          />
        </ExportWrapper>
      </Header>

      <Toolbar>
        <Toolbar.Item
          xs={6}
          md={4}
        >
          <Toolbar.BlackLabel>{translate('start-date')}</Toolbar.BlackLabel>
          <Input
            $fullWidth
            placeholder="Дата и время начала"
            value={`${format(selection.startDate, 'dd.MM.yyyy')}`}
            onClick={() => setOpen(true)}
          />
          <SingleDatePicker
            open={toggle}
            setOpen={setOpen}
            selection={selection}
            setSelection={setSelection}
          />
        </Toolbar.Item>
      </Toolbar>

      <Grid>
        <Grid.RowHeader $cols="2fr 1fr 100px">
          <Grid.Item>{translate('report-place-name')}</Grid.Item>
          <Grid.Item>{translate('position')}</Grid.Item>
          <Grid.Item>% {translate('load')}</Grid.Item>
        </Grid.RowHeader>

        {dataItems.length ? (
          dataItems.map((spot) => (
            <ReportItem
              key={spot.id}
              item={spot}
            />
          ))
        ) : (
          <NotFound>{translate('no-results')}</NotFound>
        )}
      </Grid>

      <Pagination
        inverse
        currentPage={currentPage}
        total={data?.total || 0}
        handlePageChange={setCurrentPage}
      />
    </Modal>
  );
};

export default ReportModal;

const ReportItem = ({ item }) => {
  return (
    <Grid.Row $cols="2fr 1fr 100px">
      <Grid.Item>{item.name}</Grid.Item>
      <Grid.Item>
        <Place item={item} />
      </Grid.Item>
      <Grid.Item>{item.busy}</Grid.Item>
    </Grid.Row>
  );
};

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  ${media.lg`
        margin-top: 20px;
    `}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`;
